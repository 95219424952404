import { Component, OnInit } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../../services/cacheService/cache.service';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import { UtilService } from 'src/app/services/utilService/util.service';

@Component({
  selector: 'app-google-authenticator',
  templateUrl: './google-authenticator.component.html',
  styleUrls: ['./google-authenticator.component.css']
})
export class GoogleAuthenticatorComponent implements OnInit {
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  public credentials = {
    email: null,
    token: null,
  };
  public userDetails: any;
  public gaDetails: any;
  loaders = {
    login: false,
    showResetLink: false
  };
  constructor(private bootstrapNotify: BootstrapNotifyService,
              private userService: UserService,
              private navigatorService: NavigatorService,
              private route: ActivatedRoute,
              private cacheService: CacheService,
              private utilService: UtilService) {
    // TODO: Check if a special Key is in cache before allow user access to this page.
  }
  ngOnInit(): void {/*
    this.credentials.userId = this.route.snapshot.paramMap.get('userId');
    this.userDetails = null;*/

    //get Authorized user
    this.gaDetails = JSON.parse(this.cacheService.getStorage('ga_details'))         
    this.userDetails = JSON.parse(this.cacheService.getStorage('user'))  
    console.log(this.gaDetails.setup_key, 'testtt');
           
  }
  // public resetPassword() {
  //   controls loader
  //   this.loaders.login = true;
  //   this.cacheService.deleteSession(ENV.TOKEN);
  //   this.cacheService.deleteStorage(ENV.TOKEN);
  //   if (!this.credentials.confirmPassword || !this.credentials.password) {
  //     this.bootstrapNotify.info('Provide New Password!');
  //   } else {
  //     this.userService.resetPassword(this.credentials).subscribe((response: IResponse) => {
  //       console.log('Response', response);
  //       this.loaders.login = false;
  //       this.bootstrapNotify.success(response['msg'] || 'Please proceed to login');
  //       this.navigatorService.navigateUrl('/');
  //     }, error => {
  //       this.bootstrapNotify.error(error.error.msg || 'Unable to reset password', 'right');
  //       this.loaders.login = false;
  //       console.info('Error => ', error);
  //     });
  //   }
  // }
  
  // confirm() {
  //   this._loginService.verifyAuth(this.authcode).subscribe((data) => {
  //     const result = data.body
  //     if (result['status'] === 200) {
  //       console.log(result);
  //       this.errorMessage = null;
  //       this.tfa.secret = this.tfa.tempSecret;
  //       this.tfa.tempSecret = "";
  //     } else {
  //       this.errorMessage = result['message'];
  //     }
  //   });
  // }

  verifyToken() {
    this.userService.verifyToken({...this.credentials, email: this.userDetails.email}).subscribe((response: IResponse)=> {
      if (response.status === 'success') {
        // this.cacheService.setStorage('token', response.data.access_token );
        // this.cacheService.setStorage('user', JSON.stringify( response.data.user));
        // this.cacheService.setStorage('role', JSON.stringify( response.data.roles));
        const defaultPage = response.data.user.default_page
        !!defaultPage ? this.navigatorService.navigateUrl(defaultPage): this.navigatorService.navigateUrl('/user/dashboard');
      }
    }, error => {
      this.bootstrapNotify.error(error.error.message || 'Unable verify token', 'right', error);
      this.loaders.login = false;
      console.info('Error => ', error);
    });
  }
}
