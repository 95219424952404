import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  loadingData: boolean = false;
  breadCrumb: any;

  constructor() { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'USER PROFILE',
      parent: 'Home',
      subLink: null
    };
  }

}
