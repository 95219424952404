import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { GhostTableComponent } from './ghost-table.component';



@NgModule({
  declarations: [GhostTableComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
  ],
  exports: [GhostTableComponent]
})
export class GhostTableModule { }
