import {Injectable} from '@angular/core';
import {PaymentsService} from 'src/app/services/apiCalls/payments/payments.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    constructor(private paymentSrv: PaymentsService) {
    }

    getPaymentTypes(type: string) {
        return this.paymentSrv.getPaymentTypes().pipe(map( res => {
            const response = {data: res.data.data};
            const {data}: {data: {name: string, id: number}[]} = response;
            return data.find((value) => value.name === type);
        }));
    }
}
