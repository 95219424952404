import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UserService} from '../../../../services/apiCalls/userService/user.service';
import {AuthService} from '../../../../services/authService/auth.service';
import {Router,NavigationEnd  } from '@angular/router';
import {UtilService} from '../../../../services/utilService/util.service';
import {CacheService} from '../../../../services/cacheService/cache.service';
import {DecryptService} from '../../../../services/decryptService/decrypt.service';
import {EventsService} from '../../../../services/eventServices/event.service';
import {BootstrapNotifyService} from '../../../../services/bootstrap-notify/bootstrap-notify.service';
import {NavigatorService} from '../../../../services/navigatorService/navigator.service';
import { NotificationsService } from 'src/app/services/apiCalls/notifications/notifications.service';
// import { MessagingService } from 'src/app/services/firebase/firebase.service';
// import {interval} from 'rxjs';
import { NgProgress } from 'ngx-progressbar';
import { AppNotifications } from 'src/app/services/app-notifications/notifications';
import { checkPrivilege } from 'src/app/shared/utilities/util';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends AppNotifications implements OnInit, AfterViewInit {

  public currentUser: any;
  showData: boolean = false;
  loadingData: boolean = false;
  title = 'push-notification';
  message;
  public notifications: any[] = [];
  loaders = {
    saving: false
  }
  userPermissions: any[] = [];
  // private interval = 1; // Time interval in seconds between api calls to get notification
  // private messagingService: MessagingService,
  constructor(private userService: UserService,
              private authService: AuthService,
              private utilService: UtilService,
              private alertService: BootstrapNotifyService,
              private eventService: EventsService,
              private navigateService: NavigatorService,
              private cacheService: CacheService,
              private decryptService: DecryptService,
              private bootstrapNotify: BootstrapNotifyService,
              private router: Router,
              private notificationsService: NotificationsService,
              private _progress: NgProgress,) { 
                super(utilService, notificationsService, router);

                // interval(1000 * 60 * this.interval).subscribe((val) => {
                  
                //   this.getUnreadNotifications();
                // });
               }

  ngOnInit() {
    // this.messagingService.requestPermission()
    // this.messagingService.receiveMessage()
    // this.message = this.messagingService.currentMessage;    
    this.getUnreadNotifications();
    this.getAuthUser();
    this.getuserPermissions();
  }
  ngAfterViewInit(): void {
    $('.btn-toggle-offcanvas').on('click', function() {
      console.log('Toogle OffCanvas 3');
      $('body').toggleClass('offcanvas-active');
      $('.col-3').toggleClass('offcanvas-active');
      $('#overlay').fadeToggle( 'slow', 'swing' );
    });

    $('#overlay').on('click', function() {
      $('#overlay').fadeOut('slow');
      $('body').removeClass('offcanvas-active');
      $('.col-3').removeClass('offcanvas-active');
    });

    $('.navLinks').on('click', function() {
      $('#overlay').fadeOut('slow');
      $('body').removeClass('offcanvas-active');
      $('.col-3').removeClass('offcanvas-active');
    });
  }
  getUserFromServer(user) {
    this.showData = false
    this.loadingData = true;
    this.userService.getUserDetails(user.id).subscribe(response => {
      this.currentUser = response.data
      this.loadingData = false;
      this.showData = true;
    }, error => {
      console.log('Error ', error);
      this.loadingData = false;
      this.showData = true;
    });

  }

  public getAuthUser() {
    const user = this.utilService.getAuthUser();
    const isEmpty = Object.keys(user).length === 0;
    !isEmpty && this.getUserFromServer(user)
  }


  public logOut() {
    this._progress.start();
    this.userService.logOut().subscribe(() => {
      this.authService.logOut();
      this.router.navigate(['/']);
      this._progress.done();
      this.authService.logOut();
    },
    error => {
      this.authService.logOut();
      this.authService.logOut();
      this.router.navigate(['/']);
      this._progress.done();
    });
  }

  public openProfile() {
    this.navigateService.navigateUrl(`/${this.currentUser.role.toLowerCase()}/manage-profile`);
  }

  public toggleOffCanvas() {
    console.log('Toogle OffCanvas');
    $('body').toggleClass('offcanvas-active');
  }

  setDefaultPage() {
    const currentRoute = this.router.url;
     this.loaders.saving = true
    this.userService.setDefaultPage({default_page: currentRoute}).subscribe((res) => {
      console.log('Res ', res);
      this.loaders.saving = false;
      this.alertService.success('Default page set successfully!', 'right');
    }, error => {
      console.log('Error ', error);
      this.alertService.error(error.error.message);
      this.loaders.saving = false;
    });
  }

  public getuserPermissions() {
    const user = this.utilService.getAuthUser();        
    this.userService.getUserPermissions(user.id).subscribe(response => {      
      this.userPermissions = response.data;
    }, error => {
      this.bootstrapNotify.error(error.error.message, 'Unable to fetch permissions')
      console.log('Error ', error);
    });
  }

  // private getNotifications() {
  //   this.notificationsService.getAllNotifications().subscribe(res => {
  //     this.notifications = res.data;
  //     console.log(this.notifications);
      
  //   });
  // }

  // public getUnreadNotifications() {
  //   if (!this.utilService.getAuthUser()) {
  //     return;
  //   }
  //   this.notificationsService.getUnreadNotifications().subscribe(res => {
  //     this.notifications = res.data;
  //     console.log(this.notifications, 'our notify');

  //   });
  // }

  // public markAsRead(notification) {
  //   this.notificationsService.markAsRead(notification.id).subscribe(res => {
  //     this.getUnreadNotifications();
  //     //this.goToAction(notification);
  //   });

  // }

  // private goToAction(unread) {
  //   $('#close_dyna').trigger('click');
  //   switch (unread.type) {
  //     case 'NewBidderNotification':
  //       this.__router_.navigateByUrl('/user/venues/bidders/' + unread.data.venue.slug + '-' + unread.data.venue.id);
  //       break;
  //     case 'NewDateBookingNotification':
  //       this.__router_.navigateByUrl('/user/venues/bookings/' + unread.data.venue.slug + '-' + unread.data.venue.id);
  //       break;
  //     case 'UserBiddingNotification':
  //     case 'BidDeclineNotification':
  //     case 'BidApproveNotification':
  //       this.__router_.navigateByUrl('/user/biddings');
  //       break;
  //     case 'UserBookingNotification':
  //       this.__router_.navigateByUrl('/user/bookings/');
  //       break;
  //     case 'VenueRejectedNotification':
  //     case 'VenueApprovalNotification':
  //       this.__router_.navigateByUrl('/user/venues/' + unread.data.venue.slug + '-' + unread.data.venue.id);
  //   }
  // }

  checkPrivilege (permission) {
    return checkPrivilege(permission, this.userPermissions);
  }
}
