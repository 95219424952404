import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from 'src/app/interfaces/iresponse';
import { ApiService } from '../../api/api.service.';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private api: ApiService) { }

  public getAllNotifications(): Observable<IResponse> {
    return this.api.getRequest('user', 'notifications' ).map((res: IResponse)  => {
      return res ;
    });
  }

  public getUnreadNotifications(param?) {
    return this.api.getRequest('user', 'notifications', new HttpParams({fromString: "unread=yes"}) );
  }

  public getreadNotifications() {
    return this.api.getRequest('user', 'notifications', new HttpParams({fromString: "read=yes"}) );
  }

  public markAsRead(notification_id) {
    return this.api.getRequest(`user/notifications/${notification_id}`);
  }
}
