export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBiKC2PzEo_2Jy31MkaMbVFiXHiS3hf6D4",
    authDomain: "bams-b437c.firebaseapp.com",
    databaseURL: "https://bams-b437c.firebaseio.com",
    projectId: "bams-b437c",
    storageBucket: "bams-b437c.appspot.com",
    messagingSenderId: "657103264651",
    appId: "1:657103264651:web:6e555b7aae13543289ed8e"
  },
  API_URL: 'https://bamsbacktest.uplsv.com.ng/api',

  // API_URL: 'http://159.65.222.44/api',
  API_VERSION: 'v1',
  TOKEN: 'BAMS_hj6545tyhertyuew2345678888ujgfe3234567888823f000Oluwaseun_and_Olalekan0000$&^%$0ToKENiiijyyH35Yyr6790-tgh45678ij_2020',
  PRIVILEGES: 'BAMS_privileges__000Oluwaseun_and_Olalekan0000$&^%$0ToKENiiijyyH35Yyr6790-tgh45678ij_2020',
  USER_DATA: 'BAMS_user__ty000Oluwaseun_and_Olalekan0000$&^%$0ToKENiiijyyH35Yyr6790-tgh45678ij_2020',
  USER_REVIEW_LEVELS: 'BAMS_review_levels___ty000Oluwaseun_and_Olalekan0000$&^%$0ToKENiiijyyH35Yyr6790-tgh45678ij_2020',
  USER_ROLES: 'BAMS_role___ty000Oluwaseun_and_Olalekan0000$&^%$0ToKENiiijyyH35Yyr6790-tgh45678ij_2020',
  PRIVATE_KEY: 'BAMS-hj6545tyhertyuew2345678888ujgfe323456788000000000000000iiiiiiiiijyyH35Yyr6790-tgh45678ij',
  // URL_VALIDATION:  /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
  // tslint:disable-next-line:max-line-length
  EMAIL_VALIDATION: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  SECRET_KEY: '4sW_udEk5FMqHZGsrPiykafaa973757067sW_uddEk5-Aroko-yuolalkanaFMqHZGsrPiykafaaMqHZGsrPiykafaatjqebjf',
  // tslint:disable-next-line:max-line-length
  SECRET_KEY_TOKEN: '4sW_udEk5FMqHZGsrPiykafaa973757067sW_uddEk5-Aroko-yulalkanaFMqHZGsrPiykafaaMqHZGsrPiykafaatjqebjf-2019-sidPOKJHj7ikj-o890pojrt9-2tred;sdfguytr08j-',
  SECRETENTITY: '4sW_udEk5FMqHZGsrPiykafaa973757067sW_uddEk5FsW_udEk5FMqHZGsrPiykafaaMqHZGsrPiykafaatjqebjf',
  // tslint:disable-next-line:max-line-length
  ACTION_KEY: '12384-09875poiuyty-987to890pojrt9-2976try-2019-sidPOKJHj7ikj-o890pojrt9-2tred;sdfguytr08j-987to890pojrt9-2tred;sdfguytr08j-arokoyuolalekan',

};
