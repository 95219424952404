import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../../services/utilService/util.service';
import {BootstrapNotifyService} from '../../../services/bootstrap-notify/bootstrap-notify.service';
import { UserService } from 'src/app/services/apiCalls/userService/user.service';
import { IResponse } from 'src/app/interfaces/iresponse';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { checkPrivilege } from '../../utilities/util';



@Component({
  selector: 'app-profile-shared',
  templateUrl: './profile-shared.component.html',
  styleUrls: ['./profile-shared.component.css']
})
export class ProfileSharedComponent implements OnInit {
  public profileImg = '../../../../assets/images/avater.png';
  public currentUser: any;
  public user: any;
  public cloneCurrentUser: any;
  public isEditProfile: boolean;
  public isUpdating: boolean;
  public loadingData: boolean = false;
  public showData: boolean = false;
  public submitted: boolean = false;
  public changePasswordForm: FormGroup;
  public changing_password = false;
  public show_password = {
    old: false,
    new_: false,
    confirm: false
  };
  userPermissions: any[]= [];
  private static changePasswordControl = () => {
    return {
      old_password: ['', [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required, ProfileSharedComponent.matchValues('password')]]
    };
  }
  constructor(private utilService: UtilService,
              private alertService: BootstrapNotifyService,
              private userService: UserService,
              private bootstrapNotify: BootstrapNotifyService,
              private FB: FormBuilder,) {
    this.isEditProfile = false;
    this.isUpdating = false;
    this.changePasswordForm = this.FB.group(ProfileSharedComponent.changePasswordControl());

  }

  ngOnInit() {
    this.getAuthUser();
    this.getUserPermissions();
  }

  getUserFromServer(user) {
    this.showData = false
    this.loadingData = true;
    this.userService.getUserDetails(user.id).subscribe(response => {
      this.currentUser = response.data
      this.cloneCurrentUser = {...response.data};
      this.loadingData = false;
      this.showData = true;
    }, error => {
      console.log('Error ', error);
      this.loadingData = false;
      this.showData = true;
    });
  }

  public getAuthUser() {
    const user = this.utilService.getAuthUser();
    this.getUserFromServer(user)
    console.info('Current User', this.currentUser);
    // this.cloneCurrentUser = JSON.parse(JSON.stringify(this.currentUser));
  }

  public initEdit() {
    this.isEditProfile = true;
  }

  public updateProfile() {
    this.isUpdating = true;
    console.log('Current ', this.currentUser);
    if (!this.cloneCurrentUser.first_name) {
      this.alertService.error('First name is required!');
      this.isUpdating = false;
      return;
    } else if(!this.cloneCurrentUser.last_name) {
      this.alertService.error('Last name is required!');
      this.isUpdating = false;
      return;
    } else if (!this.cloneCurrentUser.email) {
      this.alertService.error('Email is required!');
      this.isUpdating = false;
      return;
    } else if (!this.cloneCurrentUser.phone) {
      this.alertService.error('Phone Number is required!');
      this.isUpdating = false;
      return;
    } else {
      const toUpdate  = {
        phone: this.cloneCurrentUser.phone,
        first_name: this.cloneCurrentUser.first_name,
        last_name: this.cloneCurrentUser.last_name,
        email: this.cloneCurrentUser.email,
        id: this.cloneCurrentUser.userId,
        terms_conditions_status : "ACCEPTED"
      };      
      this.userService.updateProfile(toUpdate, this.currentUser.id).subscribe((res: IResponse) => {
        console.log('Respomse ', res);
        this.isEditProfile = false;
        this.isUpdating = false;
        this.getAuthUser()
        this.alertService.success('Profile Updated successfully, this will reflect next time you login!');
      }, error => {
        console.log('Error ', error);
        this.isEditProfile = false;
        this.isUpdating = false;
        this.alertService.error('Unable to update profile');
      });
    }
  }

  private static matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value) {
        return null;
      } else {
        return {isNotMatching: true};
      }
    };
  }

  changProfileImage(e) {
  }

  public changePassword() {
    this.submitted = true
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.changing_password = true;
    this.userService.changePassword(this.changePasswordForm.value).subscribe((response) => {
      this.changing_password = false;
      this.bootstrapNotify.success(response.message, 'right');
      this.submitted = false;
      this.changePasswordForm.reset();
    },error => {
      this.submitted = false;
      console.log('Error ', error);
      this.bootstrapNotify.error(error.error.message || 'Unable to change password!', 'right', error);
      this.changing_password = false;
    });
  }
  
  get old_password() { return this.changePasswordForm.get('old_password'); }
  get password() { return this.changePasswordForm.get('password'); }
  get password_confirmation() { return this.changePasswordForm.get('password_confirmation',); }

  public getUserPermissions() {
    const user = this.utilService.getAuthUser();        
    this.userService.getUserPermissions(user.id).subscribe(response => {      
      this.userPermissions = response.data;
    }, error => {
      this.bootstrapNotify.error(error.error.message, 'Unable to fetch permissions')
      console.log('Error ', error);
    });
  }
  
  public checkPrivilege (permission) {
    return checkPrivilege(permission, this.userPermissions);
  }

  public isValidInput(fieldName): boolean {
    return this.changePasswordForm.controls[fieldName].invalid &&
      (this.changePasswordForm.controls[fieldName].dirty || this.changePasswordForm.controls[fieldName].touched || this.submitted);
  }

}
