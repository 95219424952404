import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {landingRouting} from './app.routing';
import {CoreModule} from './shared/modules/core/core.module';
import {SharedModules} from './shared/modules/shared/shared.module';
import { AsyncPipe} from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule } from 'ngx-progressbar';
import {ConnectionServiceModule} from 'ng-connection-service';
@NgModule({
  declarations: [
    AppComponent,
    landingRouting.components,
    ],
  imports: [
    NgProgressModule,
    ConnectionServiceModule,
    BrowserAnimationsModule,
    BrowserModule,
    landingRouting.routes,
    CoreModule,
    SharedModules,
  ],
  providers: [landingRouting.providers, AsyncPipe ],

  bootstrap: [AppComponent]
})
export class AppModule { }
