import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { NgProgress } from './progress.service';
import'rxjs/add/operator/finally';
export class NgProgressInterceptor implements HttpInterceptor {
/**
 * @param {?} ngProgress
 */
constructor(public ngProgress: NgProgress) {
  }
/**
 * @param {?} req
 * @param {?} next
 * @return {?}
 */
intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.ngProgress.start();
    return next.handle(req).finally(() => {
      this.ngProgress.done();
    });
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: NgProgress, },
];
}

function NgProgressInterceptor_tsickle_Closure_declarations() {
/** @type {?} */
NgProgressInterceptor.decorators;
/**
 * @nocollapse
 * @type {?}
 */
NgProgressInterceptor.ctorParameters;
/** @type {?} */
NgProgressInterceptor.prototype.ngProgress;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
