


/**
 * Created by Arokoyu Olalekan Ojo
 */

import { HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import { IResponse } from 'src/app/interfaces/iresponse';
import { ApiService } from '../../api/api.service.';
import {DecryptService} from '../../decryptService/decrypt.service';
@Injectable()
export class UserService {

  constructor(private api: ApiService,
              private decryptionService: DecryptService) {

  }
  auth(data): Observable<IResponse> {
    return this.api.postRequest('login', null , data).map((res: IResponse)  => {
      return res ;
    });
  }
  saveRoles(data): Observable<IResponse> {
    return this.api.postRequest('role_permission_management', 'assign_roles_to_user' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  savePermissions(data): Observable<IResponse> {
    return this.api.postRequest('role_permission_management', 'assign_permissions_to_user' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  setDefaultPage(data): Observable<IResponse> {
    return this.api.postRequest('settings', 'set_default_page' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  saveMandate(data): Observable<IResponse> {
    return this.api.postRequest('account_management', 'account_mandates' , data).map((res: IResponse)  => {
      return res ;
    });
  }

  login(data): Observable<IResponse> {
    return this.api.postRequest('login', 'auth-sync' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  changePassword(data): Observable<IResponse> {
    return this.api.postRequest('change_password', null , data).map((res: IResponse)  => {
      return res ;
    });
  }
  verifyToken(data): Observable<IResponse> {
    return this.api.postRequest('verify_2fa_code', 'auth-sync' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  public logOut(): Observable<IResponse> {
    return this.api.postRequest('auth', 'logout', {}).map((res: IResponse)  => {
      return res ;
    });
  }

  public resetPassword(data): Observable<IResponse> {
    return this.api.postRequest('auth', 'reset-password', data).map( (res: IResponse) => {
      return res;
    });
  }
  public resetGa(id): Observable<IResponse> {
    return this.api.getRequest('2fa', `reset/${id}`).map( (res: IResponse) => {
      return res;
    });
  }
  public resetUserPasswordByAdmin(id): Observable<IResponse> {
    return this.api.getRequest('user_management', `users/reset_password/${id}`).map( (res: IResponse) => {
      return res;
    });
  }
  public toggleUsergA(id): Observable<IResponse> {
    return this.api.getRequest('user_management', `users/toggle_ga/${id}`).map( (res: IResponse) => {
      return res;
    });
  }
  public forgotPassword(data): Observable<IResponse> {
    return this.api.postRequest('forgot_password', null, data).map( (res: IResponse) => {
      return res;
    });
  }
  public getUsers(): Observable<IResponse> {
    return this.api.getRequest('user_management', 'users', ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getStatuses(): Observable<IResponse> {
    return this.api.getRequest('statuses', ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getRoles(): Observable<IResponse> {
    return this.api.getRequest('role_permission_management', 'roles', ).map((res: IResponse)  => {
      return res ;
    });
  }

  public getUserPermissions(id): Observable<IResponse> {
    return this.api.getRequest('role_permission_management', 'user_permissions/' + id).map((res: IResponse)  => {
      return res ;
    });
  }
  public deletePermission(id): Observable<IResponse> {
    return this.api.deleteRequest('role_permission_management', 'role_permissions/' + id).map((res: IResponse)  => {
      return res ;
    });
  }
  public createUser(data): Observable<IResponse> {
    return this.api.postRequest('user_management', 'users', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateUser(data, id): Observable<IResponse> {
    return this.api.putRequest('user_management', `users/${id}`, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateProfile(data, id): Observable<IResponse> {
    return this.api.postRequest('user', `update_profile/${id}`, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public getSignatoryLevels(): Observable<IResponse> {
    return this.api.getRequest('user_management', 'signatory_levels', ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getAccountSegment(): Observable<IResponse> {
    return this.api.getRequest('account_segments' ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getFundCodes(): Observable<IResponse> {
    return this.api.getRequest('fund_codes' ).map((res: IResponse)  => {
      return res ;
    });
  }
  
  public getReviewbyId(id): Observable<IResponse> {
    return this.api.getRequest('settings_management', 'review-levels/' + id ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getMandates(): Observable<IResponse> {
    return this.api.getRequest('account_management', 'account_mandates', ).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateMandate(data, id): Observable<IResponse> {
    return this.api.putRequest('account_management', 'account_mandates/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateMandateRuleName(data, id): Observable<IResponse> {
    return this.api.putRequest('account_management', 'mandate_rules/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public getMandatesDetails(id): Observable<IResponse> {
    return this.api.getRequest('account_management', 'account_mandates/' + id, ).map((res: IResponse)  => {
      return res ;
    });
  }
  public addSignatoriesToReview(data, id): Observable<IResponse> {
    return this.api.postRequest('settings_management', `review-levels/${id}/add-review-level-user`, data ).map((res: IResponse)  => {
      return res ;
    });
  }

  public getAccountTypes(): Observable<IResponse> {
    return this.api.getRequest('warrant_account_type', null).map((res: IResponse)  => {
      return res ;
    });
  }

  public getApprovals(): Observable<IResponse> {
    return this.api.getRequest('warrant_approval', null).map((res: IResponse)  => {
      return res ;
    });
  }
  public getRevenueItems(): Observable<IResponse> {
    return this.api.getRequest('revenue_management', 'revenue_items', ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getRevenueItemById(id): Observable<IResponse> {
    return this.api.getRequest('revenue_management', 'revenue_items/' + id ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getRevenueReports(): Observable<IResponse> {
    return this.api.getRequest('revenue_management', 'revenue_report/get_report').map((res: IResponse)  => {
      return res ;
    });
  }
  public getAccountBalanceReport(): Observable<IResponse> {
    return this.api.getRequest('report', 'account_balance').map((res: IResponse)  => {
      return res ;
    });
  }

  public getRevenueBudgets(): Observable<IResponse> {
    return this.api.getRequest('revenue_management', 'revenue_budgets', ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getRevenueBudgetById(id): Observable<IResponse> {
    return this.api.getRequest('revenue_management', 'revenue_budgets/' + id, ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getRevenueActuals(): Observable<IResponse> {
    return this.api.getRequest('revenue_management', 'revenue_returns', ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getRevenueActualById(id): Observable<IResponse> {
    return this.api.getRequest('revenue_management', 'revenue_returns/'+ id, ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getRevenueItemCategory(): Observable<IResponse> {
    return this.api.getRequest('revenue_management', 'revenue_item_categories').map((res: IResponse)  => {
      return res ;
    });
  }
  public getRevenueItemCategoryById(id): Observable<IResponse> {
    return this.api.getRequest('revenue_management', 'revenue_item_categories/' + id).map((res: IResponse)  => {
      return res ;
    });
  }
  public getAgencies(): Observable<IResponse> {
    return this.api.getRequest('agency', null).map((res: IResponse)  => {
      return res ;
    });
  }
  public getAgencyById(id): Observable<IResponse> {
    return this.api.getRequest('agency', id).map((res: IResponse)  => {
      return res ;
    });
  }
  public getBudgetHeads(): Observable<IResponse> {
    return this.api.getRequest('budget_head', null).map((res: IResponse)  => {
      return res ;
    });
  }
  public getBudgetHeadById(id): Observable<IResponse> {
    return this.api.getRequest('budget_head', id).map((res: IResponse)  => {
      return res ;
    });
  }
  public getWarrants(): Observable<IResponse> {
    return this.api.getRequest('warrant/warrantdatatable', null).map((res: IResponse)  => {
      return res ;
    });
  }

  public getWarrantPayments(warrantId): Observable<IResponse> {
    return this.api.getRequest('warrant_payments', null).map((res: IResponse)  => {
      return res ;
    });
  }
  public getWarrantById(warrantId: string): Observable<IResponse> {
    return this.api.getRequest('warrant', warrantId).map((res: IResponse)  => {
      return res ;
    });
  }
  public getFundCodeById(id: string): Observable<IResponse> {
    return this.api.getRequest('fund_codes', id).map((res: IResponse)  => {
      return res ;
    });
  }
  public getAccountSegmentById(id: string): Observable<IResponse> {
    return this.api.getRequest('account_segments', id).map((res: IResponse)  => {
      return res ;
    });
  }
  public getSignatoryLevelById(id: string): Observable<IResponse> {
    return this.api.getRequest('user_management/signatory_levels', id).map((res: IResponse)  => {
      return res ;
    });
  }
  public getRemittanceById(warrantId: string): Observable<IResponse> {
    return this.api.getRequest('remittances', warrantId).map((res: IResponse)  => {
      return res ;
    });
  }
  public createBudgetHeads(data): Observable<IResponse> {
    return this.api.postRequest('budget_head', null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createWarrant(data): Observable<IResponse> {
    return this.api.postRequest('warrant', null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createWarrantPayment(data): Observable<IResponse> {
    return this.api.postRequest('warrant_payments', null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createWarrantApproval(data): Observable<IResponse> {
    return this.api.postRequest('warrant_approval', null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public toggleWarrantApprovalStatus(data, id): Observable<IResponse> {
    return this.api.postRequest('warrant_approval', 'enable/' + id, null).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateBudgetHeads(data, id): Observable<IResponse> {
    return this.api.putRequest('budget_head', id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public toggleBudgetHeadStatus(id): Observable<IResponse> {
    return this.api.postRequest('budget_head', 'enable/' + id, null).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateWarrantApproval(data, id): Observable<IResponse> {
    return this.api.putRequest('warrant_approval', id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateWarrant(data, id): Observable<IResponse> {
    return this.api.putRequest('warrant', id, data).map((res: IResponse)  => {
      return res ;
    });
  }

  public createAgency(data): Observable<IResponse> {
    return this.api.postRequest('agency', null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteAgency(id): Observable<IResponse> {
    return this.api.deleteRequest('agency', id).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteWarrantApproval(id): Observable<IResponse> {
    return this.api.deleteRequest('warrant_approval', id).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteBudgetHead(id): Observable<IResponse> {
    return this.api.deleteRequest('budget_head', id).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteWarrant(id): Observable<IResponse> {
    return this.api.deleteRequest('warrant', id).map((res: IResponse)  => {
      return res ;
    });
  }

  public getBanks(): Observable<IResponse> {
    return this.api.getRequest('banks', null).map((res: IResponse)  => {
      return res ;
    });
  }
  public getAccountSummary(param?): Observable<IResponse> {
    if(param) {
      return this.api.getRequest(`bankaccount_balancerecords?bank_account_category_id=${param}`, null).map((res: IResponse)  => {
        return res ;
      });
    } else {
      return this.api.getRequest('bankaccount_balancerecords', null).map((res: IResponse)  => {
        return res ;
      });
    }
  }
  public getBankNameEnquiry(data): Observable<any> {
    return this.api.postRequest('bank-name-enquery', null, data ).map( (res: IResponse) => {
      return res;
    });
  }
  public validateTin(data): Observable<any> {
    return this.api.postRequest('tin-validation', null, data ).map( (res: IResponse) => {
      return res;
    });
  }
  public updateAgency(data, id): Observable<IResponse> {
    return this.api.patchRequest('agency', id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createRevenueItem(data): Observable<IResponse> {
    return this.api.postRequest('revenue_management', 'revenue_items', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createRevenueBudget(data): Observable<IResponse> {
    return this.api.postRequest('revenue_management', 'revenue_budgets', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createRevenueActual(data): Observable<IResponse> {
    return this.api.postRequest('revenue_management', 'revenue_returns', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateRevenueItem(data, id): Observable<IResponse> {
    return this.api.patchRequest('revenue_management', 'revenue_items/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateRevenueBudget(data, id): Observable<IResponse> {
    return this.api.patchRequest('revenue_management', 'revenue_budgets/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateRevenueActual(data, id): Observable<IResponse> {
    return this.api.patchRequest('revenue_management', 'revenue_returns/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public toggleRevenueActualStatus(id): Observable<IResponse> {
    return this.api.postRequest('revenue_management', 'revenue_return/enable/' + id, null).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateRevenueItemCategory(data, id): Observable<IResponse> {
    return this.api.patchRequest('revenue_management', 'revenue_item_categories/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createRevenueItemCategory(data): Observable<IResponse> {
    return this.api.postRequest('revenue_management', 'revenue_item_categories', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteRevenueItemCategory(id): Observable<IResponse> {
    return this.api.deleteRequest('revenue_management', 'revenue_item_categories/'+id).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteRevenueItem(id): Observable<IResponse> {
    return this.api.deleteRequest('revenue_management', 'revenue_items/' + id).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteRevenueBudget(id): Observable<IResponse> {
    return this.api.deleteRequest('revenue_management', 'revenue_budgets/' + id).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteRevenueActual(id): Observable<IResponse> {
    return this.api.deleteRequest('revenue_management', 'revenue_returns/' + id).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateFcmToken(data): Observable<IResponse> {
    return this.api.postRequest('user', 'update_fcm_token', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createSignatoryLevel(data): Observable<IResponse> {
    return this.api.postRequest('user_management', 'signatory_levels', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createStatus(data): Observable<IResponse> {
    return this.api.postRequest('statuses', null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createAccountType(data): Observable<IResponse> {
    return this.api.postRequest('warrant_account_type', null, data).map((res: IResponse)  => {
      return res ;
    });
  }

  public toggleAccountType(data, id): Observable<IResponse> {
    return this.api.postRequest('warrant_account_type', 'enable/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }

  public updateSignatoryLevel(data, id): Observable<IResponse> {
    return this.api.putRequest('user_management', 'signatory_levels/'+ id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateStatus(data, id): Observable<IResponse> {
    return this.api.putRequest( 'statuses/'+ id, null,  data).map((res: IResponse)  => {
      return res ;
    });
  }
  public toggleStatus(data, id): Observable<IResponse> {
    return this.api.putRequest( 'statuses/'+ id, null,  data).map((res: IResponse)  => {
      return res ;
    });
  }
  public saveSignatoryAccount(data, id): Observable<IResponse> {
    return this.api.postRequest('settings_management', 'review-levels/add-signatory-accounts/'+ id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateAccountSegment(data, id): Observable<IResponse> {
    return this.api.patchRequest('account_segments/' + id, null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateFundCode(data, id): Observable<IResponse> {
    return this.api.patchRequest('fund_codes/' + id, null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public saveAccountSegment(data): Observable<IResponse> {
    return this.api.postRequest('account_segments', null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public saveFundCode(data): Observable<IResponse> {
    return this.api.postRequest('fund_codes', null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteAccountSegment(id): Observable<IResponse> {
    return this.api.deleteRequest('account_segments/' + id, null).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteFundCode(id): Observable<IResponse> {
    return this.api.deleteRequest('fund_codes/' + id, null).map((res: IResponse)  => {
      return res ;
    });
  }

  public addMandateRule(data, id): Observable<IResponse> {
    return this.api.postRequest('account_management', 'account_mandates/'+ id + '/add_rule', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public addMandateRuleItem(data, id): Observable<IResponse> {
    return this.api.postRequest('account_management', 'mandate_rules/'+ id + '/add-rule-items', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateAccountType(data, id): Observable<IResponse> {
    return this.api.patchRequest('warrant_account_type', id, data).map((res: IResponse)  => {
      return res ;
    });
  }

  public deleteSignatoryLevel(id): Observable<IResponse> {
    return this.api.deleteRequest('user_management', 'signatory_levels/'+ id).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteStatus(id): Observable<IResponse> {
    return this.api.deleteRequest('statuses/'+ id, null).map((res: IResponse)  => {
      return res ;
    });
  }

  public deleteMandate(id): Observable<IResponse> {
    return this.api.deleteRequest('account_management', 'account_mandates/'+ id).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteMandateRule(id): Observable<IResponse> {
    return this.api.deleteRequest('account_management', 'mandate_rules/'+ id).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteSignatory(data,  reviewId): Observable<IResponse> {
    return this.api.postRequest('settings_management', 'review-levels/'+ reviewId + '/remove-review-level-user', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteSignatoryAcc(data,  sigId): Observable<IResponse> {
    return this.api.postRequest('settings_management', 'review-levels/remove-signatory-accounts/' + sigId , data).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteMandateRuleItem(data,  id): Observable<IResponse> {
    return this.api.postRequest('account_management', 'mandate_rules/' + id + '/remove-rule-items' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteAccountType(id): Observable<IResponse> {
    return this.api.deleteRequest('warrant_account_type', id).map((res: IResponse)  => {
      return res ;
    });
  }

  public toggleUser(data): Observable<IResponse> {
    return this.api.postRequest('user_management', 'users/toggle_status', data).map((res: IResponse)  => {
      return res ;
    });
  }

  public getUserDetails(id): Observable<any> {
    return this.api.getRequest('user_management', `users/${id}`).map( (res: any) => {
      return res;
    });
  }
  public getAdminGroups(): Observable<IResponse> {
    return this.api.getRequest('role_permission_management', 'roles').map( (res: IResponse) => {
      return res;
    });
  }
  public getRoleById(roleId): Observable<IResponse> {
    return this.api.getRequest('role_permission_management', 'roles/' + roleId).map( (res: IResponse) => {
      return res;
    });
  }
  public getPermissions(): Observable<IResponse> {
    return this.api.getRequest('role_permission_management', 'permissions').map( (res: IResponse) => {
      return res;
    });
  }
  public assignPermissions(data): Observable<IResponse> {
    return this.api.postRequest('role_permission_management', 'assign_permissions_to_role', data).map( (res: IResponse) => {
      return res;
    });
  }
  public deleteUserGroup(groupId): Observable<IResponse> {
    return this.api.deleteRequest('role_permission_management', 'roles/' + groupId).map( (res: IResponse) => {
      return res;
    });
  }

  public toggleUserGroupStatus(groupId): Observable<IResponse> {
    return this.api.getRequest('role_permission_management', 'roles/toggle_status/' + groupId).map( (res: IResponse) => {
      return res;
    });
  }
  /*
  public deletePermission(id): Observable<IResponse> {
    return this.api.deleteRequest('role_permission_management', 'role_permissions/' + id).map( (res: IResponse) => {
      return res;
    });
  }//
  public getAdminGroups(): Observable<IResponse> {
    return this.api.getRequest('role_permission_management', 'roles').map( (res: IResponse) => {
      return res;
    });
  }*/
  public createUserGroup(data): Observable<IResponse> {
    return this.api.postRequest('role_permission_management', 'roles', data).map( (res: IResponse) => {
      return res;
    });
  }
  public updateUserGroup(data): Observable<IResponse> {
    return this.api.patchRequest('role_permission_management', 'roles/' +  data.id, data).map( (res: IResponse) => {
      return res;
    });
  }
  public downloadBankTemplate(): Observable<IResponse> {
    return this.api.getRequestText('bank_account_statement', 'download_template').map( (res: IResponse) => {
      return res;
    });
  }
}