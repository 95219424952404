
export enum PaymentDataEnum {
    COMPLETED = 'completed',
    IN_PROGRESS = 'in_progress',
    FAILED = 'failed',
    PENDING = 'pending'
}

export enum PaymentTypesEnum {
    BULK_PAYMENT = 'Bulk Payment',
    VENDOR_PAYMENT = 'Vendor Payment',
    TAX_PAYMENT = 'Tax Payment',
    FUND_SWEEP_PAYMENT = 'Fund Sweep Payment',
}
