import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class FilterAccountService {

  constructor() { }
    // Observable SearchValueSource
    private _searchParams: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    // Getting the search results as an Observable
    public accountFilter$ = this._searchParams.asObservable();
  
    /**
     * getSearchResults
     * @param searchParams
     */
    public setFilter(searchParams: any) {
      this._searchParams.next(searchParams);
    }
  
    /**
     *
     * @param state
     */
    public setToInitialState(state) {
      this._searchParams.next(state);
    }
  
    /**
     *
     * @param params
     * @returns {Observable<any>}
     */
    // public filterVenue(params: any) {
    //   return this._http.post(`${this.filter_venue}`, params);
    // }
}
