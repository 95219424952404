import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../authService/auth.service';
import { CacheService } from '../cacheService/cache.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  url: string;
  constructor( private router: Router,  
               private authService: AuthService, private cache_: CacheService, private cacheService: CacheService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return false;
  }
  checkLogin(url?: string): boolean {
    if (this.authService.loggedIn(url)) { return true; }
    this.router.navigate([ '/' ]); // then ask user to login
    this.logOut();
    return false;
  }

    /**
   * Log out from system
   */
     logOut() {
      this.cache_.clearStorage();
      this.cache_.clearSession();
    }
}
