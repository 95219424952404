import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/apiCalls/userService/user.service";
import {UtilService} from "../../services/utilService/util.service";
import {BootstrapNotifyService} from "../../services/bootstrap-notify/bootstrap-notify.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NavigatorService} from "../../services/navigatorService/navigator.service";
import {ManageAccountsService} from "../../services/apiCalls/manageAccountService/manage-accounts.service";
import { VendorsService } from '../vendor/vendors.service';

@Component({
  selector: 'app-warrant-payments',
  templateUrl: './warrant-payments.component.html',
  styleUrls: ['./warrant-payments.component.css']
})
export class WarrantPaymentsComponent implements OnInit {
  public activeWarrantPayment = null;

  breadCrumb: any;
  public modal = {
    title: 'Add payment',
    btnText: 'SAVE'
  };
  showData = true;
  warrantPayments: any = [];
  vendors: any = [];
  sourceAccounts: any = [];
  vendorAccounts: any = [];

  warrantPayment =  {
    source_account_id: null,
    narration: null,
    amount: null,
    vendor_id: null,
    vendor_account_id: null,
    payment_id: null,
    warrant_id: null
  };
  warrant = null;
  loaders = {
    saving: false
  };
  loadingData: boolean = false;
  warrantId = null;
  constructor(private userService: UserService, private utilService: UtilService,
              private vendorsService: VendorsService,
              private navigatorService: NavigatorService,
              private accountService: ManageAccountsService,
              private bootstrapNotify: BootstrapNotifyService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.breadCrumb  = {
      name: 'Warrant Payments',
      parent: 'Warrant',
      subLink: null

    };
    this.warrantId = this.route.snapshot.paramMap.get('warrantId') || null;
    this.getWarrantById();
    this.getVendors();
    this.getSourceAccounts();
    this.getVendorAccounts();
  }


  public getWarrantById() {
    this.loadingData = true;
    this.userService.getWarrantById(this.warrantId).subscribe(response => {
      this.warrant = response.data;
      this.warrantPayments = this.warrant.warrant_payment || [];
      this.utilService.startDatatable('warrant-payment-list');
      console.log('Warrant ', response);
      this.loadingData = false;

    }, error => {
      console.log('Error ', error);
      this.loadingData = false;
      this.bootstrapNotify.error(error.error.message || 'Unable to get this warrant', 'right', error);
      history.back();
    });
  }

  openDialog(id) {
    this.modal = {
      title: 'Add payment',
      btnText: 'SAVE'
    };
    this.activeWarrantPayment = null;
    this.warrantPayment =  {
      source_account_id: null,
      narration: null,
      amount: null,
      vendor_id: null,
      vendor_account_id: null,
      payment_id: null,
      warrant_id: null
    };
    this.utilService.openModal(id);
  }
/*
  public getWarrantPayments() {
    this.loadingData = true;
    this.userService.getWarrantPayments(this.warrantId).subscribe(response => {
      this.warrantPayments = response.data.data;
      this.utilService.startDatatable('warrant-payment-list');
      console.log(this.warrantPayments, response);
      this.loadingData = false;
    }, error => {
      console.log('Error ', error);
      this.utilService.startDatatable('warrant-payment-list');
      this.bootstrapNotify.error(error.error.message || 'Unable to list warrant payment');
      this.loadingData = false;
    });

  }*/
  public saveWarrantPayment() {
    if(!this.warrantPayment.vendor_id) {
      return this.bootstrapNotify.info('Vendor is required!');
    } if(!this.warrantPayment.source_account_id) {
      return this.bootstrapNotify.info('Source account is required!');
    } if(!this.warrantPayment.amount) {
      return this.bootstrapNotify.info('Amount is required!');
    } else {
      this.loaders.saving = true;
      this.warrantPayment.warrant_id = this.warrantId;
        this.userService.createWarrantPayment(this.warrantPayment).subscribe((res) => {
          console.log('Res ', res);
          this.getWarrantById(); // this.getWarrantPayment()
          this.resetWarrantPayment();
          this.bootstrapNotify.success('Payment added successfully!', 'right');
          this.loaders.saving = false;
        }, error => {
          console.log('Error ', error);
          this.bootstrapNotify.error(error.error.message || 'Unable to add payment!', 'right', error);
          this.loaders.saving = false;
        });
    }
  }/*
  public updateWarrantPayment() {
    this.userService.updateWarrantPayment(this.warrantPayment, this.activeWarrantPayment.id).subscribe(response => {
      this.getWarrantPayments();
      this.resetWarrantPayment();
      this.loaders.saving = false;
      this.bootstrapNotify.success('Warrant payment updated successfully!', 'right');
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotify.error(error.error.message || 'Unable to update warrant payment!');
      this.loaders.saving = false;

    });
  }*//*
  public deleteWarrantPayment(type) {
    this.utilService.comfirmAction(() => {
      this.userService.deleteWarrantPayment(type.id).subscribe(response => {
        this.getWarrantPayments();
        this.resetWarrantPayment();
        this.bootstrapNotify.success('Warrant payment deleted successfully!', 'right');
      }, error => {
        console.log('Error ', error);
        this.bootstrapNotify.error(error.error.message || 'Unable to delete warrant payment!');
        this.loaders.saving = false;

      });
    });
  }*/
  resetWarrantPayment() {
    this.warrantPayment =  {
      source_account_id: null,
      narration: null,
      amount: null,
      vendor_id: null,
      vendor_account_id: null,
      payment_id: null,
      warrant_id: null
    };
    this.activeWarrantPayment = null;
    this.utilService.closeModal('createWarrantPaymentModal');
    this.modal = {
      title: 'Add payment',
      btnText: 'SAVE'
    };
  }

  goToPayment(payment) {
    this.navigatorService.navigateUrl('/user/payments/schedule/paymentFiles/'+ payment.id);
  }

  getVendors() {
    this.vendorsService.getVendors().subscribe(response => {
      this.vendors = response.data.data;
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotify.error(error.error.message || 'Unable to list vendors!', 'right', error);
    });
  }

  getVendorAccounts() {
    this.vendorsService.getVendorAccounts().subscribe(response => {
      console.log('Vendor accounts ', response);
      this.vendorAccounts = response.data.data;
      this.vendorAccounts.forEach((acct) => {

        acct.customName = `${acct.account_name} | ${acct.account_number}`;
        acct.bankName = acct.bank.bank_name;
      });
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotify.error(error.error.message || 'Unable to list vendor accounts!', 'right', error);
    });
  }

  getSourceAccounts() {
    this.accountService.getBankAccounts(0).subscribe(response => {
      console.log('Response ', response.data);
      this.sourceAccounts = response.data;
      this.sourceAccounts.forEach((acct) => {

        acct.customName = `${acct.account_name} | ${acct.account_number} | NGN ${acct.account_balances && acct.account_balances.balance || 0}`;
        acct.bankName = acct.bank.bank_name;
      });
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotify.error(error.error.message || 'Unable to list source accounts!', 'right', error);
    });
  }
}
