import {Directive, HostListener, Input, } from '@angular/core';
import {NgControl} from '@angular/forms';
import {map, filter} from 'rxjs/operators';

@Directive({
  selector: '[appLimitTo]',
})
export class LimitToDirective {
    @Input() limit: number;
    constructor(private ngControl: NgControl) {}
    ngOnInit() {
      const ctrl = this.ngControl.control;
      ctrl.valueChanges
        .pipe(map(v => (v || '').slice(0, this.limit)))
        .subscribe(v => ctrl.setValue(v, { emitEvent: false }));
    }
}