import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asterisks'
})
export class AsterisksPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return this.convertAsterisks(value);
  }
  public convertAsterisks(value) {
    // const regex = /(?<!^).(?!$)/g;
    if (value && value.length >= 3) {
      return value[0] + '*'.repeat(value.length - 2) + value.slice(-2);
    } else {
      return 'NO BVN PROVIDED';
    }
    // return value.replace(regex, '*');
  }
}
