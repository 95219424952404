import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from 'src/app/interfaces/iresponse';
import { ApiService } from '../../api/api.service.';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private api: ApiService) { }

  public allSettings(): Observable<IResponse> {
    return this.api.getRequest('settings', null ).map((res: IResponse)  => {
      return res ;
    });
  }

  public createSettingsFile(settingsObject) {    
   return this.api.postFile(settingsObject.data, settingsObject.formFile, 'settings', null, settingsObject.name);
  }

}


