import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UtilService} from './services/utilService/util.service';
import { ConnectionService } from 'ng-connection-service';
import { BootstrapNotifyService } from './services/bootstrap-notify/bootstrap-notify.service';
import { EventsService } from './services/eventServices/event.service';
import { Subscription } from 'rxjs';
import { LoadingService } from './services/loading-service.service';

declare const $: any;

@Component({
  selector: 'app-root',
  template: `
   <div *ngIf="pageLoader" class="global-loader-overlay">
   <div class="container">
    <div class="row">
      <div id="loader">
          <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="loading"></div>
      </div>
    </div>
  </div>
   </div>
  <router-outlet></router-outlet>`,
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked {
  isConnected = true;
  pageLoader = false
  loadingSubscription: Subscription;
  constructor(private utilServices: UtilService,
              private cdRef : ChangeDetectorRef,
              private loadingScreenService: LoadingService,
              private _connectionService: ConnectionService,
              private bootstrapNotify: BootstrapNotifyService) {
              }
  ngOnInit() {
    this._connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.bootstrapNotify.success('You\'re now connected!');
        window.location.reload();
      } else {
        this.bootstrapNotify.error('You\'re not connected, pls check your internet connection!');
      }
    });
    
    const cssRule = 'color: #3c8308;' +
      'font-size: 20px;' +
      'text-align: center' +
      'font-weight: bold;' +
      'text-shadow: 1px 1px 5px rgb(249, 162, 34);' +
      'filter: dropshadow(color=#3c8308, offx=1, offy=1);';
    setTimeout(console.info.bind(console, '%cBAMS', cssRule), 0);
  }

  ngAfterViewInit(): void {
    this.utilServices.processCommonJs2();
  }

  ngAfterViewChecked() {
    this.loadingSubscription = this.loadingScreenService.loading$.pipe().subscribe(
      (status: boolean) => {
        this.pageLoader = status;
      }
    );
    this.cdRef.detectChanges();
  }


  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
