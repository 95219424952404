import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { IResponse } from 'src/app/interfaces/iresponse';
import { ApiService } from '../../api/api.service.';

@Injectable({
  providedIn: 'root'
})
export class TaxService {

  constructor(private api: ApiService) { }

  public getTaxCategories(): Observable<IResponse> {
    return this.api.getRequest('tax_management', 'tax_categories').map((res: IResponse)  => {
      return res ;
    });
  }
  public getTaxTypes(): Observable<IResponse> {
    return this.api.getRequest('tax_management', 'tax_type').map((res: IResponse)  => {
      return res ;
    });
  }
  public getRemittances(): Observable<IResponse> {
    return this.api.getRequest('tax_management', 'tax_payment').map((res: IResponse)  => {
      return res ;
    });
  }
  public verifyTinAndSave(tin): Observable<IResponse> {
    return this.api.postRequest('tax_management', 'tin', tin).map((res: IResponse)  => {
      return res ;
    });
  }
  public createTax(data): Observable<IResponse> {
    return this.api.postRequest('tax_management', 'tax', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public getMyOrganisationTin(): Observable<IResponse> {
    return this.api.getRequest('tax_management', 'tin', ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getTaxes(): Observable<IResponse> {
    return this.api.getRequest('tax_management', 'tax', ).map((res: IResponse)  => {
      return res ;
    });
  }
    public deleteTaxCategory(id): Observable<IResponse> {
    return this.api.deleteRequest('tax_management', 'tax_categories/' + id).map((res: IResponse)  => {
      return res ;
    });
  }
  public toggleTaxType(data, id): Observable<IResponse> {
    return this.api.postRequest('tax_management', 'tax_type/enable/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public toggleTaxCategory(data, id): Observable<IResponse> {
    return this.api.postRequest('tax_management', 'tax_categories/enable/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteTaxType(id): Observable<IResponse> {
    return this.api.deleteRequest('tax_management', 'tax_type/' + id).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateTaxType(data, id): Observable<IResponse> {
    return this.api.patchRequest('tax_management', 'tax_type/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateTaxCategory(data, id): Observable<IResponse> {
    return this.api.patchRequest('tax_management', 'tax_categories/' + id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteTin(id): Observable<IResponse> {
    return this.api.deleteRequest('tax_management/tax/' + id, null).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateTin(data, id): Observable<IResponse> {
    return this.api.patchRequest('tax_management/tin/' + id, null, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createTaxType(data): Observable<IResponse> {
    return this.api.postRequest('tax_management', 'tax_type', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createTaxCategory(data): Observable<IResponse> {
    return this.api.postRequest('tax_management', 'tax_categories', data).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteTax(id): Observable<IResponse> {
    return this.api.deleteRequest('tax_management/tax', id).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateTax(data, id): Observable<IResponse> {
    return this.api.putRequest('tax_management/tax', id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateRemittance(data, id): Observable<IResponse> {
    return this.api.putRequest('remittances', id, data).map((res: IResponse)  => {
      return res ;
    });
  }
  public createRemittance(data): Observable<IResponse> {
    return this.api.postFile2(data, null,'tax_management','tax_payment', 'file_upload').map((res: IResponse)  => {
      return res ;
    });
  }

}
