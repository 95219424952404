import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../authService/auth.service';
import {Observable, of} from 'rxjs';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../cacheService/cache.service';
import {EventsService} from '../eventServices/event.service';
import {DecryptService} from '../decryptService/decrypt.service';
import {environment as ENV} from '../../../environments/environment';
import {BootstrapNotifyService} from '../bootstrap-notify/bootstrap-notify.service';
import { UtilService } from '../utilService/util.service';
import { UserService } from '../apiCalls/userService/user.service';
import 'rxjs/add/operator/map';
import { switchMap } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class GuardService implements CanActivate {
  url: string;
  constructor( private router: Router,
               private authService: AuthService,
               private cache_: CacheService,
               private cacheService: CacheService,
               ) {
  }

  /**
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<boolean> | Promise<boolean> | boolean}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.url = state.url;

    if (!this.checkLogin(this.url)) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
      // this.router.navigate(['/']);
    }
    return this.checkLogin(this.url);
  }
  /**
   * Check member login
   * @param {string} url
   * @returns {boolean}
   */
  checkLogin(url?: string): boolean {
    if (this.authService.loggedIn(url)) { return true; }
    // this.router.navigate([ '/' ]);
    this.logOut();
    return false;
  }

  /**
   * Log out from system
   */
  logOut() {
    this.cache_.clearStorage();
    this.cache_.clearSession();
  }

}

export class RoleService implements CanActivate {
  // second guard
  static getAuthUser() {
    const token = sessionStorage.getItem(ENV.TOKEN);
    const decodedToken = JWT_DECODE(token);
    return decodedToken['data']['public'];
  }
  static checkRole(): boolean {
    const user = RoleService.getAuthUser();
    console.log('USER ', user);
    return user && user.role === 'SUPER';
  }
  constructor( ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return RoleService.checkRole();
  }
  }

@Injectable()
export class PermissionsService implements CanActivate {
  constructor(private router: Router, private userService: UserService, private utilService: UtilService,
              private bootstrapNotify: BootstrapNotifyService) {
  }
     checkPermissions(permissions): Observable<boolean> | Promise<boolean> | boolean {
      const user = this.utilService.getAuthUser();
      if (user) {
        return this.userService.getUserPermissions(user.id).pipe(
          switchMap((res) => {
            if (!res.data.some(e => e.name == permissions[0])) {
              this.router.navigate(['access-denied']);
              return of(false);
            } else {
             return of(true);
            }
           })
        );
      }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const permissions = route.data && route.data.permissions;
      return this.checkPermissions(permissions);
    }
  }
