import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from 'src/app/interfaces/iresponse';
import { ApiService } from '../../api/api.service.';


@Injectable({
  providedIn: 'root'
})
export class ManageAccountsService {

  constructor(private api: ApiService) { }

  public getBankAccounts(filteredObj?, paramData?, getAllAcc?): Observable<IResponse> {
    if(filteredObj) {
      return this.api.getRequest('account_management', 'bank_accounts', new HttpParams({fromObject: filteredObj})).map( (res: IResponse) => {
        return res;
      });
    } else if(paramData) {
      console.log(paramData);
      return this.api.getRequest(`account_management/bank_accounts?page=${paramData.current_page}&pages=${paramData.payload}`, null).map( (res: IResponse) => {
        return res;
      });
    } else if(getAllAcc) {
      return this.api.getRequest('account_management/bank_accounts?all=true', null).map( (res: IResponse) => {
        return res;
      });
    }
    return this.api.getRequest('account_management', 'bank_accounts', ).map((res: IResponse)  => {
      return res ;
    });
  }

  public getBankAccountById(id): Observable<IResponse> {
    return this.api.getRequest('account_management', 'bank_accounts/' + id ).map((res: IResponse)  => {
      return res ;
    });
  }
  public createBank(data): Observable<IResponse> {
    return this.api.postRequest('account_management', 'bank_accounts', data ).map( (res: IResponse) => {
      return res;
    });
  }
  public addToCategory(data): Observable<IResponse> {
    return this.api.postRequest('account_management', 'bank_accounts/bulkaccountcategoryedit' , data ).map( (res: IResponse) => {
      return res;
    });
  }

  public updateBank(data, id): Observable<IResponse> {
    return this.api.putRequest('account_management', `bank_accounts/${id}`, data ).map( (res: IResponse) => {
      return res;
    });
  }
  public bulkAccountUpload(formFile): Observable<IResponse> {    
    return this.api.postFile({}, formFile, 'account_management/bank_accounts/bulkupload', null, "select_file").map( (res: IResponse) => {
      return res;
    });
  }
  public bankTemplate(): Observable<IResponse> {
    return this.api.getRequestText('banks', 'export').map( (res: IResponse) => {
      return res;
    });
  }
  public downloadBankCategoryTemplate(): Observable<IResponse> {
    return this.api.getRequestText('bankaccountcategory', 'export').map( (res: IResponse) => {
      return res;
    });
  }
}
