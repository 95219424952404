import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/apiCalls/userService/user.service';
import { BootstrapNotifyService } from 'src/app/services/bootstrap-notify/bootstrap-notify.service';
import { UtilService } from 'src/app/services/utilService/util.service';
import { VendorsService } from '../vendors.service';

@Component({
  selector: 'app-createvendoraccount',
  templateUrl: './createvendoraccount.component.html',
  styleUrls: ['./createvendoraccount.component.css']
})
export class CreatevendoraccountComponent implements OnInit {
  @Output() updateVendorAccount: EventEmitter<any> = new EventEmitter();
  @ViewChild('account_number') account_no: ElementRef;
  public keyUp = new Subject<KeyboardEvent>();
  private subscription: Subscription;
  public modal = {
    title: 'Add Bank Account',
    btnText: 'ADD'
  };
  banks: any = [];
  vendorId = null;
  vendorBankDetail = {
    vendor_id: null,
    bank_id: null,
    account_name: null,
    account_number: null,
  };
  activeVendorBank: any;
  loaders = {
    saving: false
  }
  loadingBanks: boolean = false;
  constructor(private utilService: UtilService,
              private bootstrapNotify: BootstrapNotifyService, 
              private userService: UserService, 
              private vendorsService: VendorsService
              ) {
              // this is the subscription for keyup event on account number field for name enquiry && tin validation
              this.subscription = this.keyUp.pipe(
                // get value
                map((event: any) => {            
                  return event.target.value;
                }), tap()
                // if character length greater then 2
                , filter(res => res.length === 10 && this.vendorBankDetail.bank_id)
                // Time in milliseconds between key events
                , debounceTime(1000)
                // If previous value is different from current
                , distinctUntilChanged()
                // subscription for response
              ).subscribe((text: string) => {
                this.loadingBanks = true;
                //proceed to name enquiry
                this.findAccount(this.vendorBankDetail.bank_id, this.account_no);
              });
               }

  ngOnInit() {
    this.getBanks();
  }

  getBanks() {
    this.userService.getBanks().subscribe(response => {      
      this.banks = response.data.data;
    });
  }

  openModal(vendorId) {
    this.vendorId = vendorId;
    this.utilService.openModal('bankDetails');
  }

  updateVendorBank() {
    this.vendorsService.updateVendorBank(this.vendorBankDetail, this.vendorId).subscribe(response => {
      this.loaders.saving = false;
      
      this.bootstrapNotify.success('Bank Account updated successfully!', 'right');
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotify.error(error.error.message || 'Unable to update Bank Account!');
      this.loaders.saving = false;
    });
  }

  resetVendorBank() {
    this.modal = {
      title: 'Add Bank Account',
      btnText: 'ADD'
    };

    this.utilService.closeModal('bankDetails');
    this.vendorBankDetail = {
      vendor_id: null,
      bank_id: null,
      account_name: null,
      account_number: null,
    };

};


  saveVendorBank() {    
    this.vendorBankDetail['vendor_id'] = this.vendorId;
    if (!this.vendorBankDetail.account_name) {
      return this.bootstrapNotify.info('Account name is required');
    } else if (!this.vendorBankDetail.account_number) {
      return this.bootstrapNotify.info('Account number is required');
    } else if (!this.vendorBankDetail.bank_id) {
      return this.bootstrapNotify.info('Bank name is required');
    } else {
      this.loaders.saving = true;
      if (this.activeVendorBank) {
        this.updateVendorBank();
      } else {
        // this.vendorDetails['business_unit_id'] = this.business_unit_id;
        this.vendorsService.addVendorBank(this.vendorBankDetail).subscribe((res) => {
          console.log('Res ', res);
          // this.getVendorBanks();
          this.loaders.saving = false;
          this.resetVendorBank();
          this.updateVendorAccount.emit();
          this.bootstrapNotify.success('Bank Account created successfully!', 'right');
        }, error => {
          console.log('Error ', error);
          this.bootstrapNotify.error(error.error.message);
          this.loaders.saving = false;
        });
      }
    }
  }

  public findAccount(bank_id, account_no) {            
    if(account_no.valid) {
      this.loadingBanks = true;
      const data = {
        account_no: account_no.value,
        bank_id: bank_id.id || bank_id
      };
      this.vendorBankDetail.account_name = null;
      this.loadingBanks = true;
      this.userService.getBankNameEnquiry(data).subscribe(
        (res) => {
          this.loadingBanks = false;
          if (res.data[0].status === "02") {
            return this.bootstrapNotify.error('Could not find account name')
            // return UtilService.alert({
            //   title: 'Invalid Account combination',
            //   text: 'Could not find account name',
            //   type: 'error',
            //   cancelButtonClass: 'btn btn-danger'
            // });
          }
          this.vendorBankDetail.account_name = `${res.data[0].surname} ${res.data[0].otherNames}`;
        },
        (err) => {
          this.loadingBanks = false;
          this.bootstrapNotify.error(err.error.message || 'Unable to fetch account name', 'right', err);
          console.log(err);
        }
      );
    }
  }
  

}
