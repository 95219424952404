import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter, map } from 'rxjs/operators';
import { ManageAccountsService } from 'src/app/services/apiCalls/manageAccountService/manage-accounts.service';
import { BootstrapNotifyService } from 'src/app/services/bootstrap-notify/bootstrap-notify.service';
import { capitalizeFirstLetter } from '../../utilities/util';

@Component({
  selector: 'app-account-dropdown',
  templateUrl: './account-dropdown.component.html',
  styleUrls: ['./account-dropdown.component.css']
})
export class AccountDropdownComponent implements OnInit, OnChanges {
  @Output() selectedValue: EventEmitter<any> = new EventEmitter();
  @Input() accType: any;
  @Input() filterArray: any[] = [];
  @Input() dropdownPosition: 'top' | 'bottom' | 'auto' = 'auto';
  @Input() reset: boolean;
  @Input() fundSweepSourceAccount = false;
  @Input() multiple = false;
  @Input() selectedAccount: any;
  @Input() getAllAccounts = false;
  accountForm: FormGroup;
  bankAccounts: any[] = [];
  bankAccountsbuffer: any[] = [];
  bankAccountData = {
    result: false,
    payload: 5,
    empty: false,
    current_page: 1
  };
  bufferSize = 10;
  allBankAccounts: any[] = [];
  numberOfItemsFromEndBeforeFetchingMore = 9;
  input$ = new Subject<string>();
  loadingData = false;
  accountValue: number;
  @ViewChild('dropDown') dropDown: NgForm;

  constructor(private _fb: FormBuilder,
              private manageaccounts: ManageAccountsService,
              private bootstrapNotify: BootstrapNotifyService) {
    this.accountForm = this._fb.group({
      'account': [null, Validators.required],
    });
  }

  ngOnInit() {
    this.getAllAccounts ? this.getBankAccounts(null, this.getAllAccounts) : this.getBankAccounts();
    // this.apiHandler.get(`${this.get_venues}?page=${page}&pages=${per_page}`);
    // this.accounts = this.bankAccounts;
    this.onSearch();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.bankAccounts = this.allBankAccounts;
    if (this.filterArray.length > 0) {
      this.bankAccounts = this.allBankAccounts.filter(elem => !this.filterArray.includes(elem.id));
      this.bankAccountsbuffer = this.bankAccounts && this.bankAccounts.slice(0, this.bufferSize);
    }
    if (!!this.selectedAccount) {
      this.accountForm.patchValue({account: this.selectedAccount});
    }
    this.bankAccountsbuffer = this.bankAccounts && this.bankAccounts.slice(0, this.bufferSize);
    this.reset && this.account.reset();
    this.reset = false;
  }

  get account() { return this.accountForm.get('account'); }

  onSearch() {
    this.input$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        // tap(() => this.loadingData = true)
    ).subscribe(value => {
      console.log(value + ' jewe66');
      this.searchBankAccounts(value);
    });
  }

  private searchBankAccounts(term, type?) {
    if (!term) {
      this.bankAccountsbuffer = this.bankAccounts.slice(0, this.bufferSize);
      return [];
    }
    term = capitalizeFirstLetter(term);
    const result = this.bankAccounts.filter((x) => x.account_name.includes(term) || x.bank.bank_name.includes(term) || x.account_number.includes(term));
    if (result.length > 0) {
      this.bankAccountsbuffer = result;
      return [];
    } else {
      this.loadingData = true;
      this.getBankAccounts(null, false, {bank_name: term});
    }
    return result;
  }



  emitValue(event) {
    if (this.fundSweepSourceAccount) {
      if (this.accountForm.value.account) {
        const selectedBankAccount = this.bankAccountsbuffer.find((arr) => {
          return arr.id == this.accountForm.value.account ;
       });
       this.selectedValue.emit({id : this.accountForm.value.account ? this.accountForm.value.account : null, balance: Math.round(selectedBankAccount.account_balance.balance)});
       return;
      }
      this.selectedValue.emit({id : this.accountForm.value.account ? this.accountForm.value.account : null, balance: 0});
     return;
    }
    this.selectedValue.emit({id : this.accountForm.value.account ? this.accountForm.value.account : null});
 }

  private fetchMore() {
      const len = this.bankAccountsbuffer.length;
      const more = this.bankAccounts.slice(len, this.bufferSize + len);
      let filtered = [];
      filtered = more.filter(ad => this.bankAccounts.every(fd => fd.id !== ad.id));
      this.loadingData = true;
      // using timeout here to simulate backend API delay
      setTimeout(() => {
          this.loadingData = false;
          this.bankAccountsbuffer = this.bankAccountsbuffer.concat(filtered);
      }, 200);
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll({ e }, acc?) {
    // if (this.loadingData || this.destBankAccounts?.length <= this.destBankAccountsBuffer?.length) {
        // return;
    // }

    // if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.destBankAccountsBuffer.length) {
    //     this.fetchMore();
    // }
  }

  getBankAccounts(params?, getAllAcc = false, filterObj = null) {
    this.manageaccounts.getBankAccounts(filterObj, params, getAllAcc).subscribe(response => {
      if (this.getAllAccounts) {
        this.allBankAccounts = this.bankAccounts = response.data;
        console.log(response);
      } else {
        this.allBankAccounts = this.bankAccounts = response.data.data;
      }
      // filter bank if filter array input exist
      if (this.filterArray.length) {
        this.bankAccounts = this.allBankAccounts.filter(elem => !this.filterArray.includes(elem.id));
      }
      this.bankAccountsbuffer = this.bankAccounts && this.bankAccounts.slice(0, this.bufferSize);
      this.loadingData = false;
    }, error => {
      this.bootstrapNotify.error(error.error.message || 'Unable to fetch accounts', 'right', error);
      console.log('Error ', error);
      this.loadingData = false;
    });
  }
}
