import { Router } from "@angular/router";
import { UtilService } from "../utilService/util.service";
import {interval} from 'rxjs';
import { NotificationsService } from "../apiCalls/notifications/notifications.service";
import { BootstrapNotifyService } from "../bootstrap-notify/bootstrap-notify.service";

export class AppNotifications {

    public notifications: any[] = [];
    private interval = 1; // Time interval in seconds between api calls to get notification
  
    constructor(private _util: UtilService,
        private notify: NotificationsService,
                // private _shared: SharedService,
                private __router_: Router) {
      if (this._util.getAuthUser()) {
        interval(1000 * 60 * this.interval).subscribe((val) => {
          this.getUnreadNotifications();
        });
      }
    }
  
    public getUnreadNotifications() {
      if (!this._util.getAuthUser()) {
        return;
      }
      this.notify.getUnreadNotifications().subscribe(res => {
        const $shake = $('#shake');
        const $blink = $('#blink');
        if (res.data.length) {
          $shake.addClass('m-animate-shake');
          $blink.addClass('m-animate-blink');
          setTimeout(() => $shake.removeClass('m-animate-shake'), 1500);
          setTimeout(() => $blink.removeClass('m-animate-blink'), 1500);
        }
        this.notifications = res.data;
        // this.notifications.forEach(notification => {
        //   const type = notification.type.split('v1\\')[1];
        //   notification.type = type;
        //   switch (type) {
        //     case 'NewBidderNotification':
        //       notification.text = notification.data.venue.name ? `New Bid for ${notification.data.venue.name}` : `New Bid for ${notification.data.venue}`;
        //       break;
        //     case 'BidApproveNotification':
        //       notification.text = notification.data.venue.name ? `Your bid for ${notification.data.venue.name} has been approved` : `Your bid for ${notification.data.venue} has been approved`;
        //       break;
        //     case 'BidDeclineNotification':
        //       notification.text = notification.data.venue.name ? `Your bid for ${notification.data.venue.name} has been declined` : `Your bid for ${notification.data.venue} has been declined`;
        //       break;
        //     case 'UserBiddingNotification':
        //       notification.text = notification.data.venue.name ? `You placed a bid for ${notification.data.venue.name}` : `You placed a bid for ${notification.data.venue}`;
        //       break;
        //     case 'NewDateBookingNotification':
        //       notification.text = notification.data.venue.name ? `New booking for ${notification.data.venue.name}` : `New booking for ${notification.data.venue}`;
        //       break;
        //     case 'UserBookingNotification':
        //       notification.text = notification.data.venue.name ? `You booked ${notification.data.venue.name}` : `You booked ${notification.data.venue}`;
        //       break;
        //     case 'NewUserCreated':
        //       notification.text = 'Welcome to VenueLog';
        //       break;
        //     case 'VenueRejectedNotification':
        //       notification.text = `Your venue, ${notification.data.venue.name} has been declined`;
        //       break;
        //     case 'VenueApprovalNotification':
        //       notification.text = `Your venue, ${notification.data.venue.name} has been approved`;
        //       break;
        //     case 'VenueDisableNotification':
        //       notification.text = `You sent a request to disable ${notification.data.venue_name}`;
        //       break;
        //     case 'VenueDisabledNotification':
        //       notification.text = `Your request to disable ${notification.data.venue_name} has been approved `;
        //       break;
        //     case 'VenueEnableNotification':
        //       notification.text = `You sent a request to enable ${notification.data.venue_name}`;
        //       break;
        //     case 'VenueEnabledNotification':
        //       notification.text = `Your request to enable ${notification.data.venue_name} has been approved `;
        //       break;
        //   }
        // });
      });
    }
  
    private goToAction(unread) {
    //   $('#close_dyna').trigger('click');
    //   switch (unread.type) {
    //     case 'NewBidderNotification':
    //       this.__router_.navigateByUrl('/user/venues/bidders/' + unread.data.venue.slug + '-' + unread.data.venue.id);
    //       break;
    //     case 'NewDateBookingNotification':
    //       this.__router_.navigateByUrl('/user/venues/bookings/' + unread.data.venue.slug + '-' + unread.data.venue.id);
    //       break;
    //     case 'UserBiddingNotification':
    //     case 'BidDeclineNotification':
    //     case 'BidApproveNotification':
    //       this.__router_.navigateByUrl('/user/biddings');
    //       break;
    //     case 'UserBookingNotification':
    //       this.__router_.navigateByUrl('/user/bookings/');
    //       break;
    //     case 'VenueRejectedNotification':
    //     case 'VenueApprovalNotification':
    //       this.__router_.navigateByUrl('/user/venues/' + unread.data.venue.slug + '-' + unread.data.venue.id);
    //   }
    }
    // VenueRejectedNotification
    // VenueApprovalNotification
  
    public markAsRead(notification) {
      this.notify.markAsRead(notification.id).subscribe(res => {
        this.getUnreadNotifications();
        this.goToAction(notification);
      });
  
    }
  }