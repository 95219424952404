import { Injectable } from '@angular/core';
import { BrowserXhr } from '@angular/http';

import { NgProgress } from './progress.service';
export class NgProgressBrowserXhr extends BrowserXhr {
private currentRequest: number = 0;
/**
 * @param {?} ngProgress
 */
constructor(private ngProgress: NgProgress) {
    super();
  }
/**
 * @return {?}
 */
public build() {
    const /** @type {?} */ xhr = super.build();

    xhr.onload = (evt) => this.done();
    xhr.onerror = (evt) => this.done();
    xhr.onabort = (evt) => this.done();

    xhr.onloadstart = (event) => {
      this.currentRequest++;
      if (!this.ngProgress.isStarted()) {
        this.ngProgress.start();
      }
    };

    return xhr;
  }
/**
 * @return {?}
 */
private done() {
    this.currentRequest--;
    if (this.currentRequest === 0) {
      this.ngProgress.done();
    }
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: NgProgress, },
];
}

function NgProgressBrowserXhr_tsickle_Closure_declarations() {
/** @type {?} */
NgProgressBrowserXhr.decorators;
/**
 * @nocollapse
 * @type {?}
 */
NgProgressBrowserXhr.ctorParameters;
/** @type {?} */
NgProgressBrowserXhr.prototype.currentRequest;
/** @type {?} */
NgProgressBrowserXhr.prototype.ngProgress;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
