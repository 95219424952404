import { Component, OnInit } from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {NavigatorService} from '../../services/navigatorService/navigator.service';

@Component({
  selector: 'app-super-user-routes',
  templateUrl: './super-user-routes.component.html',
  styleUrls: ['./super-user-routes.component.css']
})
export class SuperUserRoutesComponent implements OnInit {

  constructor(private utilService: UtilService, private navigatorService: NavigatorService) {
    // this.navigatorService.navigateUrl('super/dashboard');
  }

  ngOnInit() {
    this.utilService.processCommonJs2();
  }

}
