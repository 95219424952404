import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilService } from '../utilService/util.service';

@Injectable({
  providedIn: 'root'
})
export class GaGuard implements CanActivate {
  constructor(private _utilService: UtilService, private _router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // if (this._utilService.getTemporaryAuthStatus()) {
      //   return true;
      // }
      // this._router.navigate(['/'])
      return true;  }
}
