import {
  Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges
} from '@angular/core';
import { NgProgress } from '../services/progress.service';
export class ProgressComponent implements OnChanges {
/**
 * Progress options
 */
ease = 'linear';
   positionUsing = 'margin';
   showSpinner = true;
   direction = 'leftToRightIncreased';
   color = '#CC181E';
   thick = false;
   maximum = 1;
   minimum = 0.08;
   speed = 200;
   trickleSpeed = 300;
/**
 * Start/Stop Progressbar
 */
toggle;
/**
 * @param {?} ngProgress
 */
constructor(public ngProgress: NgProgress) {
  }
/**
 * @param {?} changes
 * @return {?}
 */
ngOnChanges(changes: SimpleChanges) {

    const /** @type {?} */ minChng = changes.minimum;
    const /** @type {?} */ maxChng = changes.maximum;
    const /** @type {?} */ spdChng = changes.speed;
    const /** @type {?} */ tklSpdChng = changes.trickleSpeed;
    const /** @type {?} */ tglChng = changes.toggle;

    if (minChng) {
      if (typeof minChng.currentValue !== 'undefined' && minChng.currentValue !== minChng.previousValue) {
        if (minChng.currentValue < 0 || minChng.currentValue > 1) {
          throw 'Input [minimum] must be between 0 and 1';
        } else {
          this.ngProgress.minimum = minChng.currentValue;
        }
      }
    }

    if (maxChng) {
      if (typeof maxChng.currentValue !== 'undefined' && maxChng.currentValue !== maxChng.previousValue) {
        if (maxChng.currentValue < 0 || maxChng.currentValue > 1) {
          throw 'Input [maximum] must be between 0 and 1';
        } else {
          this.ngProgress.maximum = maxChng.currentValue;
        }
      }
    }

    if (spdChng) {
      if (typeof spdChng.currentValue !== 'undefined' && spdChng.currentValue !== spdChng.previousValue) {
        this.ngProgress.speed = spdChng.currentValue;
      }
    }

    if (tklSpdChng) {
      if (typeof tklSpdChng.currentValue !== 'undefined' && tklSpdChng.currentValue !== tklSpdChng.previousValue) {
        this.ngProgress.trickleSpeed = tklSpdChng.currentValue;
      }
    }

    if (tglChng) {
      if (typeof tglChng.currentValue !== 'undefined' && tglChng.currentValue !== tglChng.previousValue) {
        if (tglChng.currentValue) {
          this.ngProgress.start();
        } else {
          this.ngProgress.done();
        }
      }
    }
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'ng-progress',
  template: `
  <ng-progress-bar
    [speed]="speed"
    [positionUsing]="positionUsing"
    [ease]="ease"
    [showSpinner]="showSpinner"
    [direction]="direction"
    [color]="color"
    [thick]="thick"
    [state]="ngProgress.state | async"
  ></ng-progress-bar>`,
  styles: [`
  :host {
    z-index: 999999;
    pointer-events: none;
    position: relative;
  }`],
  changeDetection: ChangeDetectionStrategy.OnPush
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: NgProgress, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'ease': [{ type: Input },],
'positionUsing': [{ type: Input },],
'showSpinner': [{ type: Input },],
'direction': [{ type: Input },],
'color': [{ type: Input },],
'thick': [{ type: Input },],
'maximum': [{ type: Input },],
'minimum': [{ type: Input },],
'speed': [{ type: Input },],
'trickleSpeed': [{ type: Input },],
'toggle': [{ type: Input },],
};
}

function ProgressComponent_tsickle_Closure_declarations() {
/** @type {?} */
ProgressComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ProgressComponent.ctorParameters;
/** @type {?} */
ProgressComponent.propDecorators;
/**
 * Progress options
 * @type {?}
 */
ProgressComponent.prototype.ease;
/** @type {?} */
ProgressComponent.prototype.positionUsing;
/** @type {?} */
ProgressComponent.prototype.showSpinner;
/** @type {?} */
ProgressComponent.prototype.direction;
/** @type {?} */
ProgressComponent.prototype.color;
/** @type {?} */
ProgressComponent.prototype.thick;
/** @type {?} */
ProgressComponent.prototype.maximum;
/** @type {?} */
ProgressComponent.prototype.minimum;
/** @type {?} */
ProgressComponent.prototype.speed;
/** @type {?} */
ProgressComponent.prototype.trickleSpeed;
/**
 * Start/Stop Progressbar
 * @type {?}
 */
ProgressComponent.prototype.toggle;
/** @type {?} */
ProgressComponent.prototype.ngProgress;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
