import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs-compat/observable/throw';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/take';
import {RestfulHttpService} from '../httpService/service.service';
import {HttpClient, HttpParams} from '@angular/common/http';
// import * as jwt_decode from 'jwt-decode';

import { environment as env } from '../../../environments/environment';
import {isNullOrUndefined} from 'util';
import {EncryptDataService} from '../encryption/encrypt-data.service';
import {Router} from '@angular/router';
import {NotificationService} from '../notificationServices/notification.service';
import {BootstrapNotifyService} from '../bootstrap-notify/bootstrap-notify.service';
/**
 *
 * Service to handle all api calls to api backend
 */
@Injectable()
export class ApiService extends RestfulHttpService {
  constructor(http: HttpClient,
              private encryptionService: EncryptDataService,
              private router: Router,
              private alertService: BootstrapNotifyService,
              private notification: NotificationService) {
    super(http);
  }


  /**
   *
   * @param error
   * @returns {Observable<never>}
   */
  errorHandler(error) {    
    try {
      // Unauthenticated
      if (error.error.code === 400 && ( error.error.message.includes('Authorization failed') || error.error.message.includes('Unauthenticated.'))) {
        sessionStorage.clear();
        (<any>$('.modal')).modal('hide');
        console.info('Error Clear ', error.error);
        localStorage.clear();
        // this.router.navigate(['/']);
        const currentRoute = this.router.url;
        this.router.navigate(['/'], { queryParams: { returnUrl: currentRoute }});
        this.alertService.error(error.error.message);
        return throwError(error.error || {message: 'Unknown error occurred'});
      } else if(error.error.code === 403) {
        this.router.navigate(['access-denied']);
        return throwError(error.error || {message: 'Unknown error occurred'});
      }
      return throwError(error || {message: 'Unknown error occurred'});
    } catch (error) {
      console.info('Type error occurred');
      return throwError(error || {message: 'Unknown error occurred'});
    }
  }


  /**
   *
   * @param res
   * @param {string} auth
   * @returns {any}
   */
  public decode(res, auth?: string) {
    const data = res.data;
    // console.info('Real Response: ', res);
    if (res && !isNullOrUndefined(res.data)) {      
      if (auth && auth.match('auth-sync')) {
        localStorage.setItem(env.TOKEN, data.access_token);
        localStorage.setItem(env.PRIVILEGES, JSON.stringify(data.permissions));
        localStorage.setItem(env.USER_DATA, JSON.stringify(data.user));
        localStorage.setItem(env.USER_REVIEW_LEVELS, JSON.stringify(data.user_review_levels));
        localStorage.setItem(env.USER_ROLES, JSON.stringify(data.role));
      }
      return res; 
    } else {
      return res;
    }
  }


  /**
   *
   * @param {string} api
   * @param {string} path
   * @param data
   * @returns {Observable<any>}
   */
  deleteRequest(api: string, path: string, data?: any): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(path)) {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + path;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.delete(ENDPOINT, data).retryWhen(error => {
      return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });
  }


  /**
   *
   * @param {string} api
   * @param {string} path
   * @param data
   * @returns {Observable<any>}
   */
  putRequest(api: string, path: string, data: any): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(path)) {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + path;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.put(ENDPOINT, data).retryWhen(error => {
      return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });

  }


  /**
   *
   * @param {string} api
   * @param {string} path
   * @param data
   * @returns {Observable<any>}
   */
  patchRequest(api: string, path: string, data: any): Observable<any> {
    let ENDPOINT;
    if (!isNullOrUndefined(path)) {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + path;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.patch(ENDPOINT, data).retryWhen(error => {
      return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });
  }


  /**
   *
   * @param {string} api
   * @param {string} path
   * @param {HttpParams} params
   * @returns {Observable<any>}
   */
  getRequestText(api: string, path?: string, params?: HttpParams): Observable<any> {
    let ENDPOINT;
    // console.info(api);
    if (!isNullOrUndefined(path)) {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + path;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    // console.info(ENDPOINT);
    return super.getText(ENDPOINT, params).retryWhen(error => {
      return error.mergeMap(err => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });
  }
  getRequest(api: string, path?: string, params?: HttpParams): Observable<any> {
    let ENDPOINT;
    // console.info(api);
    if (!isNullOrUndefined(path)) {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + path;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    // console.info(ENDPOINT);
    return super.get(ENDPOINT, params).retryWhen(error => {
      return error.mergeMap(err => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });
  }
  getExcelFileRequest(api: string, path?: string, params?: HttpParams): Observable<any> {
    let ENDPOINT;
    console.info(api);
    if (!isNullOrUndefined(path)) {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + path;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    // console.info(ENDPOINT);
    return super.getExcelFile(ENDPOINT, params).retryWhen(error => {
      return error.mergeMap(err => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => {
      return res;
    });
  }


  /**
   *
   * @param {string} api
   * @param {string} path
   * @param data
   * @returns {Observable<any>}
   */
  postRequest(api: string, path: string, data: any): Observable<any> {    
    let ENDPOINT;
    if (!isNullOrUndefined(path) && path !== 'auth-sync') {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api + '/' + path;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + api;
    }
    return super.post(ENDPOINT, data).retryWhen(error => {
      return error.mergeMap((err) => this.errorHandler(err))
        .delay(1000)
        .take(2);
    }).catch(this.errorHandler).map(res => this.decode(res, path));

  }

  public postFile2(data: any, formFile, api: string, urlLink: string, file_key = 'bulk_payment_file', method?): Observable<any> {
    let ENDPOINT;
    // const path = $.param(data);
    const urlPath = api;
      const formData = new FormData();
      let file = null;

    if (formFile && formFile.files[0]) {
      file = formFile.files[0];
      formData.append( file_key, file, file.name);

    }

    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key, index) => {
        formData.append(key, data[key]);
      });
    }
    if (!isNullOrUndefined(urlLink) && urlLink !== 'auth-sync') {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + urlPath + '/' + urlLink;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + urlPath;
    }
    return super.postFormFile(ENDPOINT, formData || {});
  }

  public postMultipleFile(data: any, formFile, api: string, urlLink: string, file_key = 'attachments', method?): Observable<any> {
    let ENDPOINT;
    // const path = $.param(data);
    const urlPath = api;
      const formData = new FormData();
      let file = null;
    if(formFile && formFile[0]) {
      for (var i = 0; i < formFile.length; i++) { 
        if(formFile[i]) {
          formData.append(`${file_key}[${i}]`, formFile[i]);          
        }
      }
    }

    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key, index) => {
        formData.append(key, data[key]);
      });
      // formData.append('source_accounts', JSON.stringify(data['source_accounts']));

    }    
    if (!isNullOrUndefined(urlLink) && urlLink !== 'auth-sync') {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + urlPath + '/' + urlLink;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + urlPath;
    }
    return super.postFormFile(ENDPOINT, formData || {});
  }

  public postFile(data: any, formFile, api: string, urlLink: string, file_key = 'file', method?): Observable<any> {
    let ENDPOINT;
    const path = $.param(data);
    const urlPath =

    Object.keys(data).length > 0 ? `${api}?${path}` :
      api;
      const formData = new FormData();
      let file = null;

    if (formFile && formFile.files[0]) {
      file = formFile.files[0];
      formData.append( file_key, file, file.name);

    }
    if (!isNullOrUndefined(urlLink) && urlLink !== 'auth-sync') {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + urlPath + '/' + urlLink;
    } else {
      ENDPOINT = env.API_URL + '/' + env.API_VERSION + '/' + urlPath;
    }
    console.log(formData.getAll(file_key));
    return super.postFormFile(ENDPOINT, formData || {});
  }
}
