import {NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {GuardService, PermissionsService, RoleService} from '../../../services/gaurdService/guard.service';
import {AuthService} from '../../../services/authService/auth.service';
import {ApiService} from '../../../services/api/api.service.';
import {UserService} from '../../../services/apiCalls/userService/user.service';
import {EnsureModuleLoadedOnceGuard} from '../moduleGuard/ensure-module-loaded-once.guard';
import { HttpInterceptorService } from 'src/app/services/http-interceptor.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NotificationService } from 'src/app/services/notificationServices/notification.service';
import { ValidationErrorService } from 'src/app/services/validation-error-service/validation-error.service';
import { EventsService } from 'src/app/services/eventServices/event.service';
import { NavigatorService } from 'src/app/services/navigatorService/navigator.service';
import { FilterAccountService } from 'src/app/services/accountsFilter/filter-account.service';
import { FilterPaymentsService } from 'src/app/services/paymentsFilter/filter-payments.service';
import { UtilService } from 'src/app/services/utilService/util.service';
import { DecryptService } from 'src/app/services/decryptService/decrypt.service';
import { EncryptDataService } from 'src/app/services/encryption/encrypt-data.service';
import { CacheService } from 'src/app/services/cacheService/cache.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@NgModule({
  imports: [
    HttpClientModule,
    DragDropModule,
  ],
  providers: [
    AuthService,
    ApiService,
    NotificationService,
    ValidationErrorService,
    EventsService,
    NavigatorService,
    FilterAccountService,
    FilterPaymentsService,
    UtilService,
    UserService,
    DecryptService,
    {
      provide: 'GuardService',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => true
    },
    RoleService,
    PermissionsService,
    EncryptDataService,
    CacheService,
    DecryptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
  ],
  declarations: []
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
