import { IRouting } from './interfaces/irouting';
import { RouterModule, Routes } from '@angular/router';
import { UserRoutesComponent } from './users/user-routes/user-routes.component';
import { LoginComponent } from './landing/login/login.component';
import { GuardService, PermissionsService, RoleService } from './services/gaurdService/guard.service';
import { SuperUserRoutesComponent } from './super-users/super-user-routes/super-user-routes.component';
import { SuperDashboardComponent } from './super-users/super-dashboard/super-dashboard.component';
import { ResetPasswordComponent } from './landing/reset-password/reset-password.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { GoogleAuthenticatorComponent } from './landing/google-authenticator/google-authenticator.component';
import { SetPasswordComponent } from './landing/set-password/set-password.component';
import { AuditComponent } from './users/audit/audit.component';
import { WarrantPaymentsComponent } from "./users/warrant-payments/warrant-payments.component";
import { TermsAndConditionsComponent } from './landing/terms-and-conditions/terms-and-conditions.component';
import { TaxPaymentComponent } from './users/payments/tax-payment/tax-payment.component';
import { CreateMandateRuleItemComponent } from './shared/components/create-mandate-rule-item/create-mandate-rule-item.component';
import { BankAccountSummaryComponent } from './shared/components/bank-account-summary/bank-account-summary.component';
import { ProfileComponent } from './users/profile/profile.component';
import { LoginGuard } from './services/gaurdService/login.guard';
import { GaGuard } from './services/gaurdService/ga.guard';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import {ReceiptComponent} from 'src/app/landing/receipt.component';


const landingRoutes: Routes = [
  // landing routes
  { path: '', component: LoginComponent, pathMatch: 'full' },
  
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },

  { path: 'terms', component: TermsAndConditionsComponent },

  { path: 'forgot-password', component: ResetPasswordComponent },

  { path: 'reset-password/token/:codeSecret/:userId', component: SetPasswordComponent },

  { path: 'google-authenticator', component: GoogleAuthenticatorComponent, canActivate: [GaGuard] },

  { path: 'not-found', component: NotFoundComponent},

  { path: 'access-denied', component: AccessDeniedComponent},

  { path: 'user', loadChildren: './shared/modules/user/user.module#UserModule' },

  { path: 'super-user', loadChildren: './shared/modules/super-user/super-user.module#SuperUserModule' },

  {  path: 'receipt', component: ReceiptComponent},
  // { path: '**', component: LoginComponent },

  { path: '**', redirectTo: 'not-found'},

];

export const landingRouting: IRouting = {
  // to load components
  routes: RouterModule.forRoot(landingRoutes, { useHash: false }),
  components: [
    LoginComponent,
    ResetPasswordComponent,
    GoogleAuthenticatorComponent,
    SetPasswordComponent,
    TermsAndConditionsComponent,
    ReceiptComponent
  ],
  entryComponent: [],
  providers: []
};
// ,
export const userRoutes: Routes = [
  {
    path: '', component: UserRoutesComponent, canActivate: [GuardService],
    children: [
      { path: 'dashboard', loadChildren: 'src/app/users/user-dashboard/user-dashboard.module#UserDashboardModule', data: {permissions: ['list dashboard']}, canActivate: [PermissionsService]},
      { path: 'accounts/account-category', loadChildren: 'src/app/users/accounts/account-category/account-category.module#AccountCategoryModule', data: {permissions: ['list bank_account_categories']}, canActivate: [PermissionsService] },
      { path: 'accounts/manage-accounts', loadChildren: 'src/app/users/accounts/manage-accounts/manage-accounts.module#ManageAccountModule', data: {permissions: ['list bank_accounts']}, canActivate: [PermissionsService]},
      { path: 'accounts/bulk-upload', loadChildren: 'src/app/users/accounts/manage-accounts/bulk-upload/bulk-upload.module#BulkUploadModule', data: {permissions: ['BulkAccountUpload']}, canActivate: [PermissionsService] },
      { path: 'accountstatement', loadChildren: 'src/app/users/account-statement/account-statement.module#AccountStatementModule' , data: {permissions: ['list bank_account_statement']}, canActivate: [PermissionsService] },
      { path: 'payments', loadChildren: 'src/app/users/payments/payments.module#PaymentsModule'},
      { path: 'business-units', loadChildren: 'src/app/users/business-unit/business-units/business-units.module#BusinessUnitsModule', data: {permissions: ['list business_unit']}, canActivate: [PermissionsService]  },
      { path: 'vendors', loadChildren: 'src/app/users/vendor/vendors.module#VendorsModule', data: {permissions: ['list vendors']}, canActivate: [PermissionsService] },
      { path: 'tax', loadChildren: 'src/app/users/tax/tax.module#TaxModule' },
      { path: 'reports', loadChildren: 'src/app/users/reports/reports.module#ReportsModule' },
      { path: 'audit', loadChildren: 'src/app/users/audit/audit.module#AuditsModule', data: {permissions: ['list audits']}, canActivate: [PermissionsService] },
      { path: 'settings',  loadChildren: 'src/app/users/setting/settings.module#SettingsModule'},
      { path: 'revenue', loadChildren: 'src/app/users/revenue-management/revenue-management.module#RevenueManagementModule'},
      { path: 'agency', loadChildren: 'src/app/users/agency/agency.module#AgencyModule', data: {permissions: ['list agency']}, canActivate: [PermissionsService]},
      { path: 'budget-heads', loadChildren: 'src/app/users/budget-heads/budget-heads.module#BudgetHeadsModule', data: {permissions: ['list budget_head']}, canActivate: [PermissionsService] },
      { path: 'warrants', loadChildren: 'src/app/users/warrants/warrants.module#WarrantsModule', data: {permissions: ['list warrant']}, canActivate: [PermissionsService] },
      { path: 'profile', component: ProfileComponent, data: {permissions: ['user.profile']}, canActivate: [PermissionsService] },
    ]
  },
  { path: '**', component: UserRoutesComponent, redirectTo: 'dashboard' },
];


export const userRouting: IRouting = {
  routes: RouterModule.forChild(userRoutes),
  components: [
    UserRoutesComponent,
    // AuditComponent,
    WarrantPaymentsComponent,
    TaxPaymentComponent,
    BankAccountSummaryComponent,
    ProfileComponent
  ],
  entryComponent: [],
  providers: []
};

export const superUserRoutes: Routes = [
  {
    path: '', component: SuperUserRoutesComponent, children: [
      { path: 'dashboard', component: SuperDashboardComponent, canActivate: [RoleService, GuardService] }

    ]
  },
  {
    path: '**', component: SuperUserRoutesComponent, children: [
      { path: 'dashboard', component: PageNotFoundComponent, canActivate: [RoleService, GuardService] },
    ]
  }
];

export const superUserRouting: IRouting = {
  routes: RouterModule.forChild(superUserRoutes),
  components: [
    SuperUserRoutesComponent,
    SuperDashboardComponent
  ],
  entryComponent: [],
  providers: []
};

