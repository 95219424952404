import { NgModule } from '@angular/core';
import { MatchValueDirective, NoWhitespaceDirective } from './common-form-validator.directive';
import { DndDirective } from './dnd/dnd.directive';
import { ExpandMenuDirective } from './expand-menu/expand-menu.directive';
import { LimitToDirective } from './input-limit/limit-to.directive';
import { StopPropagationDirective } from './stop-propagation/stop-propagation.directive';


@NgModule({
  declarations: [
    DndDirective, 
    ExpandMenuDirective, 
    LimitToDirective, 
    StopPropagationDirective, 
    MatchValueDirective, 
    NoWhitespaceDirective, 
              ],
  exports: [
    DndDirective, 
    LimitToDirective, 
    ExpandMenuDirective, 
    StopPropagationDirective, 
    MatchValueDirective, 
    NoWhitespaceDirective]
})
export class CoreDirectivesModule {}
