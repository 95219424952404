import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { BusinessListService } from 'src/app/services/apiCalls/businessService/business-list.service';
import { ManageAccountsService } from 'src/app/services/apiCalls/manageAccountService/manage-accounts.service';
import { PaymentsService } from 'src/app/services/apiCalls/payments/payments.service';
import { TaxService } from 'src/app/services/apiCalls/taxService/tax.service';
import { UserService } from 'src/app/services/apiCalls/userService/user.service';
import { BootstrapNotifyService } from 'src/app/services/bootstrap-notify/bootstrap-notify.service';
import { UtilService } from 'src/app/services/utilService/util.service';
import {PaymentTypesEnum} from 'src/app/shared/constants/payment-data.enum';
import {SharedService} from 'src/app/services/shared.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tax-payment',
  templateUrl: './tax-payment.component.html',
  styleUrls: ['./tax-payment.component.css']
})
export class TaxPaymentComponent implements OnInit {
  @Output() updatePayment: EventEmitter<any> = new EventEmitter();
  accountChartForm: any;
  loadingData: any = false;
  createTaxPaymentForm: FormGroup;
  createTaxPaymentsForm: FormGroup;
  submitted = false;
  loaders = {
    saving: false
  };
  source_account: any[] = [];
  paymentCategories: any[];
  bankAccounts: any[] = [];
  sourceBankAccounts: any[] = [];
  destBankAccounts: any[] = [];
  destBankAccountsBuffer: any[];
  taxArray: any[] = [];
  sourceBankAccountsBuffer: any[];
  bufferSize = 9;
  allBanks: any[] = [];
  businessUnits: any[] = [];
  numberOfItemsFromEndBeforeFetchingMore = 9;
  input$ = new Subject<string>();
  accType: string;
  public modal = {
    title: 'Tax Payment',
    btnText: 'Save'
  };
  taxPayment = {
    tax_id: null,
    amount: null,
    source_account_id: null,
    description: null
  };
  taxes: any[] = [];

  constructor(
              private utilService: UtilService,
              private businessList: BusinessListService,
              private bootstrapNotify: BootstrapNotifyService,
              private paymentService: PaymentsService,
              private _fb: FormBuilder,
              private _title: Title,
              private manageaccounts: ManageAccountsService,
              private userService: UserService,
              private taxService: TaxService,
              private sharedSrv: SharedService,
              private router: Router
              ) {this.createTaxPaymentForm = this._fb.group(this.taxPaymentForm());
                this.createTaxPaymentsForm = this._fb.group(this.taxPaymentsForm()); }

  ngOnInit() {
    // let set the payment type here
    this.sharedSrv.getPaymentTypes(PaymentTypesEnum.TAX_PAYMENT).subscribe(value => {
      this.createTaxPaymentsForm.get('payment_type_id').setValue(value.id);
    });
    // other init
    this.getPaymentCategories();
    this.getBankAccounts();
    this.getBusinessUnit();
    this.onSearch();
    this.fetchTax();
    this._title.setTitle('Tax payment | BAMS');
  }

  fetchTax() {
    this.taxService.getTaxes().subscribe(response => {
      this.taxArray = response.data.data;
      this.loadingData = false;
    }, error => {
      console.log('Error ', error);
      this.loadingData = false;
    });
  }

  getBusinessUnit() {
    this.loadingData = true;
    this.businessList.getBusinessUnit().subscribe(response => {
      this.businessUnits = response.data.data;
      console.log(this.businessUnits);
      this.loadingData = false;

    }, error => {
      console.log('Error ', error);
      this.loadingData = false;
    });

  }

  get description() { return this.createTaxPaymentsForm.get('description'); }
  get payment_category_id() { return this.createTaxPaymentsForm.get('payment_category_id'); }
  get source_account_id() { return this.createTaxPaymentsForm.get('source_account_id'); }
  get do_line_debits() { return this.createTaxPaymentsForm.get('do_line_debits'); }
  get tax_payments() { return this.createTaxPaymentsForm.get('tax_payments'); }
  get agency_id() { return this.createTaxPaymentsForm.get('agency_id'); }
  get fund_code_id() { return this.createTaxPaymentsForm.get('fund_code_id'); }
  get account_segment_id() { return this.createTaxPaymentsForm.get('account_segment_id'); }

  get tax_id() { return this.createTaxPaymentForm.get('tax_payments'); }
  get amount() { return this.createTaxPaymentForm.get('tax_payments'); }
  get source_account_id2() { return this.createTaxPaymentForm.get('source_account_id'); }
  get description2() { return this.createTaxPaymentForm.get('description'); }

  public taxPaymentsForm = () => {
    return {
      description: [null, Validators.required],
      payment_type_id: [null, Validators.required],
      payment_category_id: [null, Validators.required],
      do_line_debits: [null, Validators.required],
      source_accounts: [null, Validators.required],
      business_unit_id: [1],
      agency_id: [null],
      account_segment_id: [null],
      fund_code_id: [null],
      tax_payments: [null, Validators.required]
    };
  }

  public taxPaymentForm = () => {
    return {
      tax_id: [null, Validators.required],
      amount: [null, Validators.required],
      source_account_id: [null, Validators.required],
      description: [null, Validators.required]
    };
  }

  initTaxes() {
    return new FormGroup({
      tax_id: new FormControl(null, Validators.required),
      source_account_id: new FormControl('', Validators.required),
      amount: new FormControl('', Validators.required),
      description: new FormControl(null, Validators.required),
    });
  }

  updateForm(event) {
    event.id ? this.createTaxPaymentForm.controls.source_account_id.setValue(event.id) : this.createTaxPaymentForm.controls.source_account_id.setValue(null);
  }

  getBankAccounts() {
    this.loadingData = true;
    this.manageaccounts.getBankAccounts().subscribe(response => {
      const banks = response.data.data;
      this.bankAccounts = response.data.data;
      // this.bankAccounts = this.mapBankArray(banks);
      this.sourceBankAccounts = this.bankAccounts;
      this.destBankAccounts = this.bankAccounts;
      this.destBankAccountsBuffer = this.destBankAccounts.slice(0, this.bufferSize);
      this.sourceBankAccountsBuffer =  this.sourceBankAccounts.slice(0, this.bufferSize);
      this.allBanks = this.bankAccounts;
      this.loadingData = false;
    }, error => {
      console.log('Error ', error);
      this.loadingData = false;
    });
  }

  public getPaymentCategories() {
    this.loadingData = true;
    this.paymentService.getPaymentCategories().subscribe(response => {
      this.paymentCategories = response.data.data;
      this.loadingData = false;
    }, error => {
      console.log('Error ', error);
      this.loadingData = false;
    });
  }

  addTax(modalId) {
    this.utilService.openModal(modalId);
  }

  addTaxes() {
    this.taxes.push(this.createTaxPaymentForm.value);
   }

   removeTax(index) {
     this.taxes.splice(index, 1);
  }

  getTaxName(id) {
      const tax = this.taxArray.find(el => el.id = id);
      return tax && tax.name;

  }

  onKey(event, id?, i?) {
    console.log(event, 'single account');
  }

  public formatCurrency(text, element_id?: string) {
    return this.utilService. spacify(text, element_id);
  }

  public isNum(event) {
    return this.utilService.isNumberKey(event);
  }

  onScrollToEnd(type) {
    this.accType = type;
    this.fetchMore(type);
  }

  onScroll({ e }) {
    if (this.loadingData || this.destBankAccounts.length <= this.destBankAccountsBuffer.length) {
        return;
    }

    // if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.destBankAccountsBuffer.length) {
    //     this.fetchMore();
    // }
  }

  onSearch() {
    this.input$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => this.fakeService(term))
    )

    .subscribe(data => {
      if (data) {
        let arr = [data];
        this.accType === 'destBankAccountsBuffer' ? this.destBankAccountsBuffer = arr.slice(0, this.bufferSize) : this.sourceBankAccountsBuffer = arr.slice(0, this.bufferSize);
        arr = [];
      }
      });
  }

  private fakeService(term, type?) {
    if (!term) {
      this.accType === 'destBankAccountsBuffer' ? this.destBankAccountsBuffer = this.destBankAccounts.slice(0, this.bufferSize) : this.sourceBankAccountsBuffer = this.sourceBankAccounts.slice(0, this.bufferSize);
      return [];
    }
    const result = this.accType === 'destBankAccountsBuffer' ? this.destBankAccounts.filter((x) => x.account_name.includes(term) || x.bank.bank_name.includes(term)) : this.sourceBankAccounts.filter((x) => x.account_name.includes(term) || x.bank.bank_name.includes(term));
    if (result.length > 0) {
      this.accType === 'destBankAccountsBuffer' ? this.destBankAccountsBuffer = result : this.sourceBankAccountsBuffer = result;
      return [];
    }
    return result;
  }

  private fetchMore(type?) {
    if (type === 'destBankAccountsBuffer') {
      const len = this.destBankAccountsBuffer.length;
      const more = this.destBankAccounts.slice(len, this.bufferSize + len);
    let filtered = [];
      filtered = more.filter(ad =>
      this.destBankAccountsBuffer.every(fd => fd.id !== ad.id));
    console.log(filtered);
      this.loadingData = true;
      // using timeout here to simulate backend API delay
      setTimeout(() => {
          this.loadingData = false;
          this.destBankAccountsBuffer = this.destBankAccountsBuffer.concat(filtered);
      }, 200);
    } else {
      const len = this.sourceBankAccountsBuffer.length;
      const more = this.sourceBankAccounts.slice(len, this.bufferSize + len);
    let filtered = [];
      filtered = more.filter(ad =>
      this.sourceBankAccountsBuffer.every(fd => fd.id !== ad.id));
    console.log(filtered);
      this.loadingData = true;
      // using timeout here to simulate backend API delay
      setTimeout(() => {
          this.loadingData = false;
          this.sourceBankAccountsBuffer = this.sourceBankAccountsBuffer.concat(filtered);
      }, 200);
    }
  }

  setAccType(type) {
    this.accType = type;
  }

  submit() {
    // delete control if fund code, agency id or account segment is empty
    if (!this.fund_code_id.value || !this.agency_id.value || !this.account_segment_id.value) {
      delete this.createTaxPaymentsForm.value.agency_id;
      delete this.createTaxPaymentsForm.value.fund_code_id;
      delete this.createTaxPaymentsForm.value.account_segment_id;
    }
    this.tax_payments.setValue(this.taxes);
    this.loaders.saving = true;
    this.paymentService.makeTaxPayment(this.createTaxPaymentsForm.value).subscribe(response => {
      this.getPaymentCategories();
      this.bootstrapNotify.success('Payment Initiated successfully!', 'right');
      this.loaders.saving = false;
      this.createTaxPaymentsForm.reset();
      this.updatePayment.emit();
      this.router.navigate(['user/payments']);
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotify.error(error.error.message || 'Unable to initiate payment!', 'right', error);
      this.loaders.saving = false;
    });
  }

  saveTax() {
    this.addTaxes();
    this.tax_payments.setValue(this.taxes);
    this.utilService.closeModal('taxpayment');
    this.createTaxPaymentForm.reset();
    this.submitted = true;
  }

  updateFormValue(event) {
    setTimeout(() => {
      this.accountChartForm = event;
      if (event.valid) {
        this.createTaxPaymentsForm.patchValue(this.accountChartForm.value);
      }
    }, 1);
  }

}
