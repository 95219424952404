import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

declare const $: any;

/**
 *
 * @param {string} type
 * @returns {boolean}
 */
export const passwordToggle = (type: string) => {
  return (type !== 'SHOW');
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const checkPrivilege  = (permission, userPermissions) => {
  if(userPermissions.length) {
    return userPermissions.some(e => e.name == permission);
  }
}

export class DashboardHelpers {
  constructor() {
  }
  /**
 * dashboard progress bar
 * @param balance
 */
   public static progressBar(balance){    
      if(balance <= 1000000000 ) {
        return 25;
      } else if(balance > 1000000000 && balance <= 2000000000) {
        return 50;
      } else if(balance > 2000000000 && balance <= 3000000000) {
        return 50;
      } else if(balance > 3000000000 && balance <= 4000000000) {
        return 50;
      } else {
        return 75
      }
    }
}

/**
 *
 * @param {string} elementId
 */
export const passwordPreview = (elementId): void => {
  const PASSWORD =   document.getElementById(elementId);
  if (PASSWORD.getAttribute('type') === 'password') {
    PASSWORD.setAttribute('type', 'text');
  } else {
    PASSWORD.setAttribute('type', 'password');
  }
};

// scroll to top
export const scrollTop = () => {
  window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
}

// Capitalize the first letter of a string
export const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

// Calculate the number of days between two dates
export const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

// Convert a string to a number implicitly
export const toNumber = str => +str;

// Check if an array contains any items
export const isNotEmpty = arr => Array.isArray(arr) && arr.length > 0;

// Different ways of merging multiple arrays
// Merge but don't remove the duplications
export const merge1 = (a, b) => a.concat(b);

// Sort an array containing numbers
export const sort = arr => arr.sort((a, b) => a - b);

// Generate a random HEX color
export const randomColor = () => `#${Math.random().toString(16).slice(2, 8).padStart(6, '0')}`;

// Get the value of a specified cookie
export const cookie = name => `; ${document.cookie}`.split(`; ${name}=`).pop().split(';').shift();

// Swap the values of 2 variables
let a = 1;
let b = 2;
[a, b] = [b, a];
// Result: 
// a = 2
// b = 1

// Get the text that the user has selected
export const getSelectedText = () => window.getSelection().toString();

// 

/**
 *
 * @param {FormGroup} formGroup
 */
 export const validateAllFormFields = (formGroup: FormGroup) => {         // {1}
  Object.keys(formGroup.controls).forEach(field => {  // {2}
    const control = formGroup.get(field);             // {3}
    if (control instanceof FormControl) {             // {4}
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {        // {5}
      validateAllFormFields(control);            // {6}
    }
  });
}

// export const isNumberKey = (evt) => {
//   const charCode = evt.which;

//   if ( (charCode !== 8 || charCode === 32 ) && (charCode < 48 || charCode > 57) && (charCode !== 37 || charCode !== 39)) {
//     return false;
//   }
// }

/**
 *
 * @param {string} action
 * @param {string} modalId
 * @param {string} type
 */
 export const triggerModalOrOverlay = (action: string, modalId: string, type?: string) => {
  if (type === 'STATIC') {
    $(`#${modalId}`).modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  (action === 'SHOW') ? $(`#${modalId}`).modal('show') : $(`#${modalId}`).modal('hide');
  // (action === "open") ? this.overlay.open(modalId, 'slideInLeft') : this.overlay.close(modalId, () => {
  // });
  if (type === 'DRAGGABLE') {
    $(`#${modalId}`).draggable({
      handle: '.modal-header'
    });
  }
};

/**
 *
 * @param {string} errorMessage
 * @param {Object | Array<string> | Array<Object>} error
 * @returns {string}
 */
export const errorHelper = (errorMessage: string, error: object | Array<string> | Array<object> | HttpErrorResponse): string => {
  let response = '';
  // first condition is to check if its an error instance
  if (error instanceof HttpErrorResponse) {
    // check if no response in connection
    if (error.status === 0) {
      response = 'Can\'t connect to the server, please check your network or contact administrator';
    }
    if (error.status === 511) {
      // TODO if session expired
      response = error.error.message + '<br>';
    }

    if (error.status === 500) {
      response = 'Server Error 500 ... Contact Administrator';
    }

    if (error.status === 422) {
      // TODO if bad request
      if (!error.error.errors || typeof(error.error.errors) === 'string') {
        response = error.error.message + '<br>';
      } else {
        if (error.error.errors && isObjectEmpty(error.error.errors)) {
          response = `<i>* ${error.error.message}</i><br>`;
        } else {
          response = `<i>* Validation Error</i><br>`;
          for (const err in error.error.errors) {
            if (error.error.errors.hasOwnProperty(err)) {
              console.log( error.error.errors[err]);
              error.error.errors[err].forEach((e) => {
                response += '. ' + e + '<br>';
              });
            }
          }
        }
      }
    }

    if (error.status === 400) {
      // TODO if bad request
      if (!error.error.data || typeof(error.error.data) === 'string') {
        response = error.error.message + '<br>';
      } else {
        if (error.error.data && isObjectEmpty(error.error.data)) {
          response = `<i>* ${error.error.message}</i><br>`;
        } else {
          for (const err in error.error.data) {
            if (error.error.data.hasOwnProperty(err)) {
              console.log( error.error.data[err]);
              response += error.error.data[err];
              // error.error.errors[err].forEach((e) => {
              //   response += '. ' + e + '<br>';
              // });
            }
          }
        }
      }
    }
  } else {
    response = error && error['error'] ? error['error']['message'] : errorMessage;
  }
  return response || error['error']['message'] || errorMessage;
};

/**
 *
 * @param arr
 * @param obj
 */
export  const pushToArray = (arr, obj) => {
  const index = arr.findIndex((e) => e.id === obj.id);
  if (index === -1) {
    arr.unshift(obj);
  } else {
    arr[index] = obj;
  }
};

/**
 *
 * @param arr
 * @param obj
 */
export const removeFromArray = (arr, obj) => {
  const index = arr.findIndex(e => e.id === obj.id);
  if (index === -1) {
    return;
  } else {
    arr.splice(index, 1);
  }
};

/**
 *
 * @param object
 */
export const setObjectValuesToNull = (object) => {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      object[key] = '';
    }
  }
};

/**
 *
 * @param {object} obj
 * @returns {string[]}
 */
export const convertObjectToArray = (obj: object): string[] => {

  const convertedArray = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      convertedArray.push(obj[key]);
    }
  }
  return convertedArray;

};

/**
 *
 * @param value
 */
export const UCFirst = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};


/**
 *
 * @param originalArray
 * @param props
 * @returns {any[]}
 */
export const removeArrayDuplicate = (originalArray, props?) => {
    let newArray = [];
    const lookupObject  = {};

    if (props) {

      // tslint:disable-next-line: forin
      for (const i in originalArray) {
        lookupObject[originalArray[i][props]] = originalArray[i];
      }

      // tslint:disable-next-line: forin
      for (const i in lookupObject) {
        newArray.push(lookupObject[i]);
      }

      return newArray;

    }

    newArray = Array.from(new Set(originalArray));
    return newArray;

};


/**
 * check if obj is empty
 * @param obj
 * @returns {boolean}
 */
export const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

/**
 * check if input is empty or white space
 * @param str
 * @returns {boolean}
 */
export const isEmptyOrSpaces = (str) => {
  return str === null || str.trim().length > 0;
};


/**
 * reload page
 */
export const reloadPage = () => {
  return window.location.reload();
};

/**
 *
 * @param value
 * @param array
 * @return {boolean}
 */
export const isInArray = (value, array) => {
  return array.indexOf(value) > -1;
};

/**
 * Comma separate amount inputs
 * @param text
 * @param element_id
 */
export const specify = (text, element_id?: string) => {
  const value = text.replace(/,/g, '');
  const matches = /^(?:(\d{1,3})?((?:\d{3})*))((?:,\d*)?)$/.exec(value);
  if (!matches) {
    return;
  }

  // add a comma before every group of three digits
  const modified = matches[2].replace(/(\d{3})/g, ',$1');

  // now splice it all back together
  const result = [matches[1], modified, matches[3]].join('');
  if (element_id) {
    const amountElement = (document.getElementById(element_id) as HTMLInputElement);
    const cursorPosition = amountElement.selectionStart;
    // console.log(cursorPosition);
    amountElement.value = result;
    // setCaretPosition(amountElement, cursorPosition);
  }
  return result;
};

/**
 *
 * @param evt
 */
export const isNumberKey = (evt) => {
  const theEvent = evt || window.event;
  let key;

  if (theEvent.type === 'paste') {
    key = evt.clipboardData.getData('text/plain');
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  const regex = /[0-9]|\./;
  if ( !regex.test(key) ) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) {
      theEvent.preventDefault();
    }
  }
};

/**
 *
 * @param arr
 * @param initValue
 */
export const reduce = (arr: Array<any>, initValue = 0) => {
  return arr.reduce((a, c) => {
    return a + Number(c['value'] || c);
  }, initValue);
};

/**
 *
 * @param payload
 * @param type
 */
export const dateConverter = (payload: any, type: string) => {
  let date: any;
  if (type === 'DOUBLE') {
    if (!payload || (payload && payload.date === '') || (payload && payload.date === null) || payload.date === undefined) {
      date = {startDate: '', endDate: ''};
    } else {
      date = {
        startDate: moment(payload && payload.date[0]).format('YYYY-MM-DD'),
        endDate: moment(payload && payload.date[1]).format('YYYY-MM-DD')
      };
    }
  } else {
    date = moment(payload).format('YYYY-MM-DD');
  }
  return date;
};

export const spacify = (text, element_id?: string) => {
  const value = text.replace(/,/g, '');
  const matches = /^(?:(\d{1,3})?((?:\d{3})*))((?:,\d*)?)$/.exec(value);
  if (!matches) {
    return;
  }

  // add a comma before every group of three digits
  const modified = matches[2].replace(/(\d{3})/g, ',$1');

  // now splice it all back together
  const result = [matches[1], modified, matches[3]].join('');
  if (element_id) {
    const amountElement = document.getElementById(element_id);
    const cursorPosition = amountElement['selectionStart'];
    // console.log(cursorPosition);
    amountElement['value'] = result;
    // setCaretPosition(amountElement, cursorPosition);
  }
  return result;
};

export const getObjectById = (id, array) => { 
  if(array) {
    const arr = array.find((arr)=> {
      return arr.id == id;
   })
   if(arr){
     return arr
   }
  }   
}

export function greaterThan(value?: any, field?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let validation: boolean;
    if (field) {
      const fieldToCompare = control.root.get(field);
      validation = fieldToCompare && Number(control.value) > Number(fieldToCompare.value);
    } else {
      validation = Number(control.value) > Number(value);
    }
    // return isLessThan ? {lessThan: {value: control.value}} : null;
    return validation ? {maxVal: true} : null;
  }
}
export const exportCsv = (data: any[], fileName: string) => {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};
  XLSX.writeFile(workbook, fileName);
};
export const exportExcel = (data: any[], fileName: string) => {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  console.log('worksheet', worksheet);
  // const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: [`${excelFileName}`] };
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, worksheet, `${fileName}`);
  XLSX.writeFile(wb, fileName);
};

