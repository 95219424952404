import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/apiCalls/userService/user.service';
import { BootstrapNotifyService } from 'src/app/services/bootstrap-notify/bootstrap-notify.service';
import { UtilService } from 'src/app/services/utilService/util.service';
import { checkPrivilege } from '../../utilities/util';

@Component({
  selector: 'app-initiate-payment-dropdown',
  templateUrl: './initiate-payment-dropdown.component.html',
  styleUrls: ['./initiate-payment-dropdown.component.css']
})
export class InitiatePaymentDropdownComponent implements OnInit, AfterViewInit {
  userPermissions: any[] = []

  constructor(private utilService: UtilService,
              private userService: UserService,
              private bootstrapNotify: BootstrapNotifyService) { }

  ngOnInit() {
    this.getUserPermissions();
  }

  ngAfterViewInit() {
    $('body').click(function(evt){
      if(evt.target.id == "new_btn")
        return;
      //For descendants of menu_content being clicked, remove this check if you do not want to put constraint on descendants.
      if($(evt.target).closest('#new_btn').length)
        return;

      //Do processing of click event here for every element except with id menu_content
      $('.new-drop').removeClass('d-block');
    });
  }

  
  public getUserPermissions() {
    const user = this.utilService.getAuthUser();        
    this.userService.getUserPermissions(user.id).subscribe(response => {      
      this.userPermissions = response.data;
    }, error => {
      this.bootstrapNotify.error(error.error.message, 'Unable to fetch permissions')
      console.log('Error ', error);
    });
  }

  public checkPrivilege (permission) {
    return checkPrivilege(permission, this.userPermissions);
  }

  public showNewOptions() {
    $('.new-drop').addClass('d-block');
  }

}
