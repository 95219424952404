import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FilterAccountService } from '../../../services/accountsFilter/filter-account.service';

@Component({
  selector: 'app-bank-account-summary',
  templateUrl: './bank-account-summary.component.html',
  styleUrls: ['./bank-account-summary.component.css']
})
export class BankAccountSummaryComponent implements OnInit, OnChanges {
  @Input() accounts: any ;
  @Output() updateAccountComponent: EventEmitter<any> = new EventEmitter();
  activeBanks: any[] = []
  negativeBalance: any[] = []
  zeroBalance: any[] = []
  uniqueBanks: any[] = []

  constructor(private _filterAccount: FilterAccountService, private _router: Router) { }

  ngOnInit() {

  } 

  ngOnChanges() {
    if(!!this.accounts) {
      this.getBanksWithActiveBalance(this.accounts)                
      this.getNegativeBalance(this.accounts)                
      this.getZeroBalance(this.accounts) 
    }
  } 

  getBanksWithActiveBalance(data) {
    this.activeBanks = data.filter((obj)=> {      
       return Number(obj.account_balance && obj.account_balance.balance) > 0
    }) 
  }
  getNegativeBalance(data) {
    this.negativeBalance = data.filter((obj)=> {
      return Number(obj.account_balance && obj.account_balance.balance) < 0
    }) 
  }
  getZeroBalance(data) {
    this.zeroBalance = data.filter((obj)=> {
      return Number(obj.account_balance && obj.account_balance.balance && obj.account_balance.balance) === 0
    }) 
  }

  public filterBankAccounts(value: string, type: string) {
    const filter = {
      type: type,
      value: value
    };
    this._filterAccount.setFilter(filter);
    this._router.url.includes('manage-accounts')?this.updateAccountComponent.emit():this._router.navigate(['user/accounts/manage-accounts']);
  }

}
