import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/apiCalls/userService/user.service';

@Component({
  selector: 'app-include-account-chart',
  templateUrl: './include-account-chart.component.html',
  styleUrls: ['./include-account-chart.component.css']
})
export class IncludeAccountChartComponent implements OnInit {
  @Output() emitFormValue: EventEmitter<any> = new EventEmitter();
  includeAccountChart: boolean = false
  loadingData: boolean = false
  fundCode: any[];
  agencies: any[];
  accountSegment: any[];
  createAccountChartForm: FormGroup
  constructor(private userService: UserService, private _fb: FormBuilder) {
    this.createAccountChartForm = this._fb.group(this.accountChart());
   }
  ngOnInit() {
    this.onChanges();
    this.getAgencies();
    this.getAccountSegment();
    this.getFundCode();
  }
  public getFundCode() {
    this.loadingData = true;
    this.userService.getFundCodes().subscribe(response => {
      this.fundCode = response.data.data;      
      this.loadingData = false;
    }, error => {
      console.log('Error ', error);
      this.loadingData = false;
    });
  }
  public getAgencies() {
    this.loadingData = true;
    this.userService.getAgencies().subscribe(response => {
      this.agencies = response.data.data;
      this.loadingData = false;
    }, error => {
      console.log('Error ', error);
      this.loadingData = false;
    });
  }
  public getAccountSegment() {
    this.loadingData = true;
    this.userService.getAccountSegment().subscribe(response => {
      this.accountSegment = response.data.data;
      this.loadingData = false;
    }, error => {
      console.log('Error ', error);
      this.loadingData = false;
    });
  }
  updateFormState(event) {
    const checked = event.target.checked
    if(checked) {
      this.includeAccountChart = true
      this.agency_id.setValidators([Validators.required]);
      this.account_segment_id.setValidators([Validators.required]);
      this.fund_code_id.setValidators([Validators.required]);
      this.createAccountChartForm.updateValueAndValidity()
      this.emitFormValue.emit(this.createAccountChartForm)
    } else {
      this.includeAccountChart = false
      this.agency_id.clearValidators();
      this.account_segment_id.clearValidators();
      this.fund_code_id.clearValidators();
      this.agency_id.updateValueAndValidity()
      this.account_segment_id.updateValueAndValidity()
      this.fund_code_id.updateValueAndValidity()
      this.createAccountChartForm.updateValueAndValidity()
      this.emitFormValue.emit(this.createAccountChartForm)

    }
  }
  public accountChart = () => {
    return {
      agency_id: [null],
      account_segment_id: [null],
      fund_code_id: [null],
    };
  }

  onChanges(): void {
    this.createAccountChartForm.valueChanges.subscribe(val => {
      this.emitFormValue.emit(this.createAccountChartForm)
    });
    
  }

  get agency_id() { return this.createAccountChartForm.get('agency_id'); }
  get fund_code_id() { return this.createAccountChartForm.get('fund_code_id'); }
  get account_segment_id() { return this.createAccountChartForm.get('account_segment_id'); }


}
