import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import {BootstrapNotifyService} from '../../services/bootstrap-notify/bootstrap-notify.service';
import {UserService} from '../../services/apiCalls/userService/user.service';
import {IResponse} from '../../interfaces/iresponse';
import {NavigatorService} from '../../services/navigatorService/navigator.service';
import * as JWT_DECODE from 'jwt-decode';
import {CacheService} from '../../services/cacheService/cache.service';
import {ActivatedRoute, Router} from '@angular/router';
import { NgForm } from '@angular/forms';
import { UtilService } from 'src/app/services/utilService/util.service';
import { Title } from '@angular/platform-browser';
import { environment as env } from '../../../environments/environment';
import { AuthService } from 'src/app/services/authService/auth.service';
declare const $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit,  AfterViewInit{
  @ViewChild('loginForm')form: NgForm;
  public EMAIL_VALIDATION: any =  ENV.EMAIL_VALIDATION;
  public twofaStatus: boolean = false
  public token: string;
  public userData: string;
  public credentials = {
    email: null,
    password: null,
    token: undefined
    // grant_type: 'password',
    // client_id: '2',
    // client_secret: 'Ic2XYaKhQnCcT0lH0IYeX7TH7EuzvtnygeG2P8j5',
    // username: null,
    // scope: '*'
  };
  public userDetails: any;
  loaders = {
    login: false,
    showResetLink: false
  };
  public returnUrl: string;

  constructor(private bootstrapNotify: BootstrapNotifyService,
              private userService: UserService,
              private cacheService: CacheService,
              private navigateService: NavigatorService,
              private router: Router,
              private _route: ActivatedRoute,
              private authService: AuthService,
              private _title: Title) {
                this.token  = env.TOKEN;
                this.userData  = env.USER_DATA;
    // redirect to home if already logged in
    // There will still be a route gaurd for login
    if (this.authService.loggedIn()) {
      this.router.navigate(['/user/dashboard']);
    }      
  }
  ngOnInit(): void {  
    this._title.setTitle('Login | BAMS');
    this.userDetails = null;
    // get return url from route parameters or default to '/user/dashboard'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/user/dashboard';
  }

  ngAfterViewInit() {
    $("input.email").each(function(){
      $(this).blur(function(){
      if($(this).val()===""){
      $(this).attr("Placeholder","Plese enter your email");
      }
      });
      });
    $("input.email").each(function(){
      $(this).blur(function(){
      if($(this).val()===""){
      $(this).attr("Placeholder","Plese enter your email");
      }
      });
      });
  }
  
  public loginProcess() {
    //clear what we have in session and local storage
    this.cacheService.clearStorage();
    this.cacheService.clearSession();
    this.loaders.login = true;
    if (!this.credentials.email || !this.credentials.password) {
      this.bootstrapNotify.info('Provide login details!');
      this.loaders.login = false;
    } else if(this.twofaStatus && !this.credentials.token) {
      this.loaders.login = false;
      return this.bootstrapNotify.info('Provide token!');
    } else {
      this.userService.login(this.credentials).subscribe((response: IResponse) => {
        // this.loaders.login = false;
          // const decodedToken = JWT_DECODE(response.data.access_token);
        // console.log('Decoded => ', decodedToken);
        if(response.code === 200) {
          //Todo we should check for status 200 here
          // this.cacheService.setStorage(this.token, response.data.access_token );
          // this.cacheService.setStorage('user', JSON.stringify( response.data.user));
          // this.cacheService.setStorage('role', JSON.stringify( response.data.roles));
          const defaultPage = response.data.user.default_page
          !!defaultPage ? this.navigateService.navigateUrl(defaultPage): this.navigateService.navigateUrl(this.returnUrl);
          return;
        }
        if( response.code == 206) {
          this.cacheService.setStorage('ga_details', JSON.stringify( response.data.ga_details));
          this.cacheService.setStorage(this.userData, JSON.stringify( response.data.user));
          if(response.data.twofa_status) {
            this.twofaStatus = response.data.twofa_status;            
            return this.bootstrapNotify.info(response.message, 'right');
          } else {
            this.navigateService.navigateUrl('google-authenticator');
          }
        }
      }, error => {
        this.bootstrapNotify.error(error.error.message || 'Unable to login', 'right', error);
        this.loaders.login = false;
        console.info('Error => ', error);
      });
    }
  }
}
