import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from 'src/app/interfaces/iresponse';

import { ApiService } from '../../api/api.service.';



@Injectable({
  providedIn: 'root'
})
export class BusinessListService {

  constructor(private api: ApiService ) { }
  public getBusinessUnit(): Observable<IResponse> {
    return this.api.getRequest('business_unit_management', 'business_unit', ).map((res: IResponse)  => {
      return res ;
    });
  }
  public createBusinessUnit(data): Observable<IResponse> {
    return this.api.postRequest('business_unit_management', 'business_unit', data  ).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateBusinessUnit(data, id): Observable<IResponse> {
    return this.api.putRequest('business_unit_management', `business_unit/${id}`, data  ).map((res: IResponse)  => {
      return res ;
    });
  }
  public updateBusinessUnitAccount(data: any): Observable<IResponse> {
    return this.api.postRequest('business_unit_management', 'business_unit_account/store_multiple', data  ).map((res: IResponse)  => {
      return res ;
    });
  }
  public deleteBusinessUnitAccount(data): Observable<IResponse> {
    return this.api.postRequest('business_unit_management', 'business_unit_account/remove_account' , data).map((res: IResponse)  => {
      return res ;
    });
  }
  public getBusinessUnitDetail(id): Observable<IResponse> {
    return this.api.getRequest('business_unit_management', `business_unit/${id}`).map((res: IResponse)  => {
      return res ;
    });
  }
  public getBusinessUnitBank(id): Observable<IResponse> {
    return this.api.getRequest('business_unit_management', `business_unit_account/${id}`).map((res: IResponse)  => {
      return res ;
    });
  }
  public toggleBusinessUnit(data): Observable<IResponse> {
    return this.api.postRequest('business_unit_management', 'business_unit/toggle_status', data).map( (res: IResponse) => {
      return res;
    });
  }


}
