import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentDatePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    return moment(value, 'YYYYMMDD').fromNow();
  }

}
