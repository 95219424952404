import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/apiCalls/userService/user.service';
import { BootstrapNotifyService } from 'src/app/services/bootstrap-notify/bootstrap-notify.service';
import { UtilService } from 'src/app/services/utilService/util.service';
@Component({
  selector: 'app-create-mandate-rule-item',
  templateUrl: './create-mandate-rule-item.component.html',
  styleUrls: ['./create-mandate-rule-item.component.css']
})
export class CreateMandateRuleItemComponent implements OnInit, OnChanges {
  @Input() showGrid: boolean = false;
  @Input() mandateRuleId: number;
  @Input() noApiCall: boolean = false;
  @Input() selectedSignatories: any[];
  @Output() value: EventEmitter<any> = new EventEmitter();
  currentMandateId: any;
  reviewLevels: any[] = [];
  createMandateRuleItem: FormGroup;
  signatoryLevels: any[] = [];
  paymentTypes: any[] = [];
  filteredSignatories : any[] = [];
  breadCrumb: any;
  public modal = {
    title: 'Add Mandate Rule',
    btnText: 'SAVE'
  };
  showData = true;
  loaders = {
    saving: false
  };
  loadingData: boolean = false;

  constructor(private _fb: FormBuilder, private userService: UserService, private bootstrapNotify: BootstrapNotifyService, private utilService: UtilService) {
    this.createMandateRuleItem = this._fb.group(this.mandateRule());
   }

  ngOnInit() {
    this.getSignatoryLevels()    
  }
  ngOnChanges(changes: SimpleChanges) {
    if(this.showGrid) {
      this.createMandateRuleItem.removeControl('name'); 
    }   
    this.showGrid && this.filterSignatoryLevel()
  }

  public getSignatoryLevels() {
    this.loadingData = true;
    this.userService.getSignatoryLevels().subscribe(response => {
      this.signatoryLevels = response.data.data;
      this.filteredSignatories = this.signatoryLevels;
      this.loadingData = false;
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotify.error(error.error.message || 'Unable to list signatory level', 'right', error);
      this.loadingData = false;
    });
  
  }
  public mandateRule = () => {
    return {
      name: [null, Validators.required],
      mandate_rule_items: this._fb.array([
        // this._fb.group({
        //   signatory_level_id: new FormControl(null,Validators.required),
        //   signatory_count: new FormControl('',Validators.required),
        // })
      ])
    };
  }

  mandateRuleItems() {
    return new FormGroup({
      signatory_level_id: new FormControl(null,Validators.required),
      signatory_count: new FormControl(null,Validators.required),
    });
  }

  saveMandateRule() {
    this.loaders.saving = true;
    this.userService.addMandateRule(this.createMandateRuleItem.value, this.currentMandateId).subscribe(response => {
      this.createMandateRuleItem.reset()
      this.createMandateRuleItem = this._fb.group(this.mandateRule());
      this.loaders.saving = false;
      this.value.emit();
      this.utilService.closeModal('mandateRuleModal');
      this.bootstrapNotify.success('Mandate rule added successfully!', 'right');
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotify.error(error.error.message || 'Unable to add mandate rule!', 'right', error);
      this.loaders.saving = false;
    });
  }

  saveMandateRuleItems() {
    this.loaders.saving = true;
    this.userService.addMandateRuleItem(this.createMandateRuleItem.value, this.mandateRuleId).subscribe(response => {
      this.createMandateRuleItem.reset()
      this.createMandateRuleItem = this._fb.group(this.mandateRule());
      this.loaders.saving = false;
      this.value.emit(response);
      this.bootstrapNotify.success('Mandate rule item added successfully!', 'right');
    }, error => {
      console.log('Error ', error);
      this.bootstrapNotify.error(error.error.message || 'Unable to add mandate rule item!', 'right', error);
      this.loaders.saving = false;
    });
  }

  get ruleItem () {return this.createMandateRuleItem.get('mandate_rule_items') as FormArray;}
  get name() { return this.createMandateRuleItem.get('name'); }
  get mandate_ruleItem() { return this.createMandateRuleItem.get('mandate_rule_items'); }

  addRuleItem() {
    this.filterSignatoryLevel(this.createMandateRuleItem.value.mandate_rule_items)
    const newItem = this._fb.group({
      signatory_level_id: new FormControl(null,Validators.required),
      signatory_count: new FormControl('',Validators.required),
    })
    this.ruleItem.push(newItem);
  }
  
  removeRuleItem(index) {
    this.ruleItem.removeAt(index);
    this.filterSignatoryLevel(this.createMandateRuleItem.value.mandate_rule_items)
  }

  openModal(id) {
    this.currentMandateId = id;
    this.utilService.openModal('mandateRuleModal')
  }

  updateMandateForm() {
    this.value.emit(this.createMandateRuleItem.value);
    this.utilService.closeModal('mandateRuleModal')
  }
  /**
   * filter signatory levels
   * @param arr 
   */
  filterSignatoryLevel(arr?) {
    if(arr) {
      if(!this.showGrid) {
        return this.filteredSignatories = this.signatoryLevels.filter((elem) => !arr.find(({ signatory_level_id }) => elem.id === signatory_level_id));
      } else {
        // filter by current signatory level && selected signatory levels
        const filter = this.signatoryLevels.filter((elem) => !arr.find(({ signatory_level_id }) => elem.id === signatory_level_id));
        return this.filteredSignatories = filter.filter((elem) => !this.selectedSignatories.find(({ signatory_level_id }) => elem.id === signatory_level_id));
      }
    }
    this.filteredSignatories = this.signatoryLevels.filter((elem) => !this.selectedSignatories.find(({ signatory_level_id }) => elem.id === signatory_level_id));
  }
  reset() {
    this.createMandateRuleItem.reset()
  }

}
