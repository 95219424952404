import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UtilService} from '../../services/utilService/util.service';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  Event,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { NgProgress } from "ngx-progressbar";




@Component({
  selector: 'app-user-routes',
  templateUrl: './user-routes.component.html',
  styleUrls: ['./user-routes.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserRoutesComponent implements OnInit {

  constructor(private utilService: UtilService, private _router: Router, private _progress: NgProgress,) {
    // this.navigatorService.navigateUrl('user/dashboard');
    
    this._router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this._progress.start()
         break;
        }

        case event instanceof NavigationEnd: {
          this._progress.done();
          break;
        }

        case event instanceof NavigationCancel: {
          this._progress.done();

          break;
        }
        case event instanceof NavigationError: {
          this._progress.done();

          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.utilService.processCommonJs2();
  }
}
