import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from 'src/app/interfaces/iresponse';
import { ApiService } from '../../api/api.service.';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private api : ApiService) { }

  public getAllPayments(reviewLevelId?): Observable<IResponse> {
    if(reviewLevelId) {
      return this.api.getRequest('payment_management', 'payments', new HttpParams({fromString: `review_level_id=${reviewLevelId}`})).map( (res: IResponse) => {
        return res;
      });
    }
    return this.api.getRequest('payment_management', 'payments', ).map((res: IResponse)  => {
      return res ;
    });
  }
  
  public getPaymentById(id): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'payments/' + id, ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getPaymentTypes(): Observable<IResponse> {
    return this.api.getRequest('payment_types' ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getTransactionReport(): Observable<IResponse> {
    return this.api.getRequest('report', 'transaction' ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getPaymentDetails(id): Observable<IResponse> {
    return this.api.getRequest('payment_management', `payments/${id}`).map( (res: IResponse) => {
      return res;
    });
  }
  public getPaymentBenefitiaries(id, params): Observable<IResponse> {
    return this.api.getRequest('payment_management', `payments/${id}/beneficiaries`, params).map( (res: IResponse) => {
      return res;
    });
  }
  public getPaymentCategories(): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'payment_categories', ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getPaymentCategoryById(id) {
    return this.api.getRequest('payment_management', 'payment_categories/'+ id, ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getPaymentMainCategories(): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'payment_main_categories', ).map((res: IResponse)  => {
      return res ;
    });
  }

  public createPaymentMainCategory(data): Observable<IResponse> {
    return this.api.postRequest('payment_management', 'payment_main_categories', data).map((res: IResponse)  => {
      return res ;
    });
  }

  public createPaymentCategory(data): Observable<IResponse> {
    return this.api.postRequest('payment_management', 'payment_categories', data).map((res: IResponse)  => {
      return res ;
    });
  }

  public makeVendorPayment(data): Observable<any> {
      return this.api.postMultipleFile(data, data.attachments, 'payment_management/payments', 'vendor/initiate', 'attachments' ).map( (res: IResponse) => {
        return res;
      });
    }
  public makeFundSweepPayment(data): Observable<IResponse> {
      return this.api.postMultipleFile(data, data.attachments, 'payment_management/payments', 'fund_sweep/initiate', 'attachments' ).map( (res: IResponse) => {
        return res;
      });
    }
  public makeBulkPayment(data): Observable<IResponse> {
      return this.api.postMultipleFile( data, data.attachments, 'payment_management/payments', 'bulk/initiate', 'attachments' ).map( (res: IResponse) => {
        return res;
      });
    }
  public makeTaxPayment(data): Observable<IResponse> {
      return this.api.postFile2( data, null, 'payment_management/payments', 'bulk/initiate', null ).map( (res: IResponse) => {
        return res;
      });
    }

  public updatePaymentMainCategory(data, id): Observable<IResponse> {
    return this.api.putRequest('payment_management', 'payment_main_categories/'+ id, data).map((res: IResponse)  => {
      return res ;
    });
  }

  public updatePaymentCategory(data, id): Observable<IResponse> {
    return this.api.putRequest('payment_management', 'payment_categories/'+ id, data).map((res: IResponse)  => {
      return res ;
    });
  }

  public deletePaymentCategory(id): Observable<IResponse> {
    return this.api.deleteRequest('payment_management', 'payment_categories/'+ id).map((res: IResponse)  => {
      return res ;
    });
  }

  public deletePaymentMainCategory(id): Observable<IResponse> {
    return this.api.deleteRequest('payment_management', 'payment_main_categories/'+ id).map((res: IResponse)  => {
      return res ;
    });
  }
  public getPaymentMainCategoryById(id) {
    return this.api.getRequest('payment_management', 'payment_main_categories/'+ id, ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getBulkPaymentFiles(): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'bulk_payment' ).map((res: IResponse)  => {
      return res ;
    });
  }
  public getBulkPaymentFileById(id): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'bulk_payment/'+ id ).map((res: IResponse)  => {
      return res ;
    });
  }

  public processBulkPaymentFiles(bulkPaymentid): Observable<IResponse> {
    return this.api.getRequest('payment_management', `bulk_payment/process/${bulkPaymentid}` ).map((res: IResponse)  => {
      return res ;
    });
  }

  public deleteBulkPaymentFile(id): Observable<IResponse> {
    return this.api.deleteRequest('payment_management', 'bulk_payment/delete_bulk_payment_file/'+ id ).map((res: IResponse)  => {
      return res ;
    });
  }
  public skipAccountValidation(id): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'bulk_payment/skip_account_validation/'+ id ).map((res: IResponse)  => {
      return res ;
    });
  }
  public listAttachments(id): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'bulk_payment/list_attachments/' + id ).map((res: IResponse)  => {
      return res ;
    });
  }
  public downloadAccountValidationReport(id): Observable<any> {
    return this.api.getExcelFileRequest('payment_management', 'bulk_payment/download_account_validation_report/' + id ).map((res)  => {
      return res ;
    });
  }

  public downloadAccounts(id): Observable<any> {
    return this.api.getExcelFileRequest('payment_management', 'bulk_payment/download_accounts/' + id ).map((res)  => {
      return res ;
    });
  }
  public downloadContentValidationReport(id): Observable<IResponse> {
    return this.api.getExcelFileRequest('payment_management', 'bulk_payment/download_content_validation_report/' + id ).map((res: IResponse)  => {
      return res ;
    });
  }
  public listBulkPaymentAccounts(id): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'bulk_payment/list_accounts/' + id ).map((res: IResponse)  => {
      return res ;
    });
  }
  public listBulkPaymentAttachments(id): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'bulk_payment/list_attachments/' + id ).map((res: IResponse)  => {
      return res ;
    });
  }
  public paymentAttachments(id): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'payments/attachments/' + id ).map((res: IResponse)  => {
      return res ;
    });
  }
  public togglePaymentMainCategory(id): Observable<IResponse> {
    return this.api.postRequest('payment_categories', 'enable/' + id, null).map((res: IResponse)  => {
      return res ;
    });
  }
  public togglePaymentCategory(id): Observable<IResponse> {
    return this.api.postRequest('payment_main_categories', 'enable/' + id , null).map((res: IResponse)  => {
      return res ;
    });
  }

  public checkValidationComplete(): Observable<IResponse> {
    return this.api.getRequest('payment_management', 'bulk_payment/status_check' ).map((res: IResponse)  => {
      return res ;
    });
  }
  
  public validateAccounts(id): Observable<IResponse> {
    return this.api.getRequest('payment_management', `bulk_payment/account_validation/${id}` ).map((res: IResponse)  => {
      return res ;
    });
  }

  public paymentAction(reviewLevelId, data): Observable<IResponse> {    
    return this.api.postRequest('payment_management', `payments/${reviewLevelId}/multiple-review`, data ).map((res: IResponse)  => {
      return res ;
    });
  }

  public getPaymentReport(): Observable<IResponse> {
    return this.api.getRequest('report', 'payment').map((res: IResponse)  => {
      return res ;
    });
  }

  
}
