import { Directive, HostBinding, HostListener, ViewChild } from '@angular/core';

@Directive({
  selector: '[appExpandMenu]'
})
export class ExpandMenuDirective {
  // @ViewChild('targetEle') targetEle: HTMLElement;

  @HostBinding('class.showDropdown') isOpen = false;
  @HostListener('click', ["$event"]) public toggleOpen(event: any): void
  {
      this.isOpen = !this.isOpen;
  }

  // @HostListener('click') toggleOpen($event){
  //   console.log($event, 'tttttttttttt');
    
  //   this.isOpen = !this.isOpen;
  //   }
  constructor() { }

}
