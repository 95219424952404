import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from '../components/progress-bar.component';
import { ProgressComponent } from '../components/progress.component';
import { NgProgress } from '../services/progress.service';
export class NgProgressModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  declarations: [
    ProgressComponent,
    ProgressBarComponent
  ],
  exports: [
    ProgressComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    NgProgress
  ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function NgProgressModule_tsickle_Closure_declarations() {
/** @type {?} */
NgProgressModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
NgProgressModule.ctorParameters;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
