import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CacheService} from '../../../../services/cacheService/cache.service';
import {NavigatorService} from '../../../../services/navigatorService/navigator.service';
import {IResponse} from '../../../../interfaces/iresponse';
import {ReportService} from '../../../../services/apiCalls/reportService/report.service';
import {UtilService} from '../../../../services/utilService/util.service';
import {Router} from '@angular/router';
import { UserService } from 'src/app/services/apiCalls/userService/user.service';
import { BootstrapNotifyService } from 'src/app/services/bootstrap-notify/bootstrap-notify.service';
import { Location } from '@angular/common';

declare const $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  // set active bar

  public reportFile: any;
  public currentUser: any;
  userPermissions: any[] = [];
  static updateActiveBar(url) {
    setTimeout(() => {
      console.info('URL : ', url);
      let oldUrl;
      oldUrl = 'dashboard';
      $('.nav-link-special').removeClass('active');
      $('.nav-link-special').removeClass('active');
      $('.nav-link-special a').blur();
      $(`#${url}`).addClass('active');
      if ($(`#${url}`).hasClass('active')) {
        oldUrl = url;
      } else {
        $(`#${oldUrl}`).addClass('active');
      }
    }, 500);
  }
  constructor(
    private route: Router,
    private userService: UserService ,
    private utilService: UtilService,
    private bootstrapNotify: BootstrapNotifyService,
    private location: Location
  ) {

  }
  ngOnInit() {
    this.getuserPermissions();
   }
  ngAfterViewInit(): void {
    const routes = this.route.url.split('/');
    SidebarComponent.updateActiveBar(routes[routes.length - 1]);
    const navLink = document.querySelectorAll('.nav-link-special');
    navLink.forEach((el) => {
      // el.addEventListener('click', (e) => {
      //   $('.navLinks').toggleClass('active');
      //   $('.childNavDropDown').toggleClass('in');
      //   const id = el['id'];
      //   if (id.includes('#') || !id) {
      //     return false;
      //   }
      //   $(`#${id}`).addClass('active');
      //   $(`#${id} > ul`).addClass('in');
      // });
    });
  }
  public getuserPermissions() {
    const user = this.utilService.getAuthUser();
    this.userService.getUserPermissions(user.id).subscribe(response => {
      this.userPermissions = response.data;
    }, error => {
      this.bootstrapNotify.error(error.error.message, 'Unable to fetch permissions');
      console.log('Error ', error);
    });
  }

  checkPrivilege (permission) {
    if (this.userPermissions.length) {
      return this.userPermissions.some(e => e.name == permission);
    }
  }

  routeTo(routeUrl, permission) {
    if (this.checkPrivilege(permission)) {
      this.route.navigate([routeUrl]);
      return;
    }
    UtilService.alert('Access Denied!', 'You do not have access to view this page', 'warning', false, 'Go back').then((res) => {
      if (res.value) {
        const routes = this.route.url.split('/');
        SidebarComponent.updateActiveBar(routes[routes.length - 1]);
      }
    });
  }

}
