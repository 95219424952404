import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {NotificationService} from '../../../services/notificationServices/notification.service';
import {ValidationErrorService} from '../../../services/validation-error-service/validation-error.service';
import {EventsService} from '../../../services/eventServices/event.service';
import {NavigatorService} from '../../../services/navigatorService/navigator.service';
import {EncryptDataService} from '../../../services/encryption/encrypt-data.service';
import {CacheService} from '../../../services/cacheService/cache.service';
import {GuardService, RoleService, PermissionsService} from '../../../services/gaurdService/guard.service';
import {NotifyComponent} from '../../components/notify/notify.component';
import {SidebarComponent} from '../../layout/dashbord/sidebar/sidebar.component';
import {FooterComponent} from '../../layout/dashbord/footer/footer.component';
import {HeaderComponent} from '../../layout/dashbord/header/header.component';
import {DecryptService} from '../../../services/decryptService/decrypt.service';
import {NgSelectModule} from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';
import {PageNotFoundComponent} from '../../components/page-not-found/page-not-found.component';
import {AsterisksPipe} from '../../pipes/Asterisks/asterisks.pipe';
import {ChangePasswordComponent} from '../../components/change-password/change-password.component';
import {BreadcrumbComponent} from '../../layout/dashbord/breadcrumb/breadcrumb.component';
import { MomentDatePipe } from '../../pipes/moment-date.pipe';
import { ProfileSharedComponent } from '../../components/profile-shared/profile-shared.component';
import { AttachmentFilesComponent } from '../../components/attachment-files/attachment-files.component';
import { AccessDeniedComponent } from '../../components/access-denied/access-denied.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { InitiatePaymentDropdownComponent } from '../../components/initiate-payment-dropdown/initiate-payment-dropdown.component';
import { CoreDirectivesModule } from '../../directives/directives';
import { GhostTableModule } from '../../components/ghost-table/ghost-table.module';
import { AccountDropdownComponent } from '../../components/account-dropdown/account-dropdown.component';
import { IncludeAccountChartComponent } from '../../components/include-account-chart/include-account-chart.component';
import { CreatevendoraccountComponent } from 'src/app/users/vendor/createvendoraccount/createvendoraccount.component';
import { CreateMandateRuleItemComponent } from '../../components/create-mandate-rule-item/create-mandate-rule-item.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
// import {DataTablesModule} from 'angular-datatables';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    RouterModule,
    ChartsModule,
    CoreDirectivesModule,
    GhostTableModule,
    
    ],
  declarations: [
    NotifyComponent,
    SidebarComponent,
    PageNotFoundComponent,
    FooterComponent,
    BreadcrumbComponent,
    HeaderComponent,
    ChangePasswordComponent,
    AsterisksPipe,
    MomentDatePipe,
    ProfileSharedComponent,
    AttachmentFilesComponent,
    AccessDeniedComponent,
    InitiatePaymentDropdownComponent,
    NotFoundComponent,
    AccountDropdownComponent,
    IncludeAccountChartComponent,
    CreatevendoraccountComponent,
    CreateMandateRuleItemComponent

  ],
  entryComponents: [],
  providers: [
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NotifyComponent,
    SidebarComponent,
    PageNotFoundComponent,
    FooterComponent,
    BreadcrumbComponent,
    ChangePasswordComponent,
    HeaderComponent,
    AsterisksPipe,
    NgSelectModule,
    ChartsModule,
    MomentDatePipe,
    ProfileSharedComponent,
    AttachmentFilesComponent,
    AccessDeniedComponent,
    NotFoundComponent,
    InitiatePaymentDropdownComponent,
    CoreDirectivesModule,
    AccountDropdownComponent,
    GhostTableModule,
    IncludeAccountChartComponent,
    CreatevendoraccountComponent,
    CreateMandateRuleItemComponent,
    NgbPopoverModule
  ]
})
export class SharedModules { }
