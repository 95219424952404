import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from 'src/app/interfaces/iresponse';
import { ApiService } from 'src/app/services/api/api.service.';
@Injectable({
  providedIn: 'root'
})
export class VendorsService {

  constructor(private api: ApiService) { }

  public getVendors(): Observable<IResponse>  {
    return this.api.getRequest('vendor_management', 'vendors?per_page=9000', ).map((res: IResponse)  => {
      return res ;
    });
  }

  public updateVendor(data, id): Observable<IResponse> {
    return this.api.putRequest('vendor_management', `vendors/${id}`, data ).map( (res: IResponse) => {
      return res;
    });
  }
  public createVendor(data): Observable<IResponse> {
    return this.api.postRequest('vendor_management', 'vendors', data ).map( (res: IResponse) => {
      return res;
    });
  }


  public getDetails(id): Observable<IResponse> {
    return this.api.getRequest('vendor_management', `vendors/${id}`).map( (res: IResponse) => {
      return res;
    });
  }
  public getVendorAccount(id): Observable<IResponse> {
    return this.api.getRequest('vendor_management', `vendor_accounts/${id}`).map( (res: IResponse) => {
      return res;
    });
  }
  public getVendorAccounts(): Observable<IResponse> {
    return this.api.getRequest('vendor_management', `vendor_accounts`).map( (res: IResponse) => {
      return res;
    });
  }

  public addVendorBank(data): Observable<IResponse> {
    return this.api.postRequest('vendor_management', 'vendor_accounts', data ).map( (res: IResponse) => {
      return res;
    });
  }
  public updateVendorBank(data, id): Observable<IResponse> {
    return this.api.putRequest('vendor_management', `vendor_accounts/${id}`, data ).map( (res: IResponse) => {
      return res;
    });
  }


  public toggleVendor(data): Observable<IResponse> {
    return this.api.postRequest('vendor_management', 'vendors/toggle_status', data).map( (res: IResponse) => {
      return res;
    });
  }
  public toggleVendorAccount(data): Observable<IResponse> {
    return this.api.postRequest('vendor_management', 'vendor_accounts/toggle_status', data).map( (res: IResponse) => {
      return res;
    });
  }

}
