/**
 *
 * Created By Arokoyu Olalekan Ojo <arokoyuolalekan@gmail.com> @ 8/9/2019
 *
 */
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CacheService} from '../cacheService/cache.service';
import {HttpClient} from '@angular/common/http';
import { environment as ENV } from '../../../environments/environment';
import * as JWT_DECODE from 'jwt-decode';
import {DecryptService} from '../decryptService/decrypt.service';
import {UtilService} from '../utilService/util.service';
import {UserService} from '../apiCalls/userService/user.service';

@Injectable()
export class AuthService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private decryptData: DecryptService,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute, private cache: CacheService) {
  }

  /**
   *
   * @returns {boolean}
   */
  public loggedIn(url?: string): boolean {
    return this.checkUserLogin(url);
  }
  /**
   *
   * @returns {boolean}
   */
  public checkPrivilege(url): boolean {
    // const userRole = this.utilService.getAuthUser();
    return true ;// userRole.role === 'USER' || userRole.role === 'SUPER';
    //TODO: loop the user roles return on login to match all the possible role who can access the route/
  }

  /**
   * Ensure user / member sign in
   * @returns {boolean}
   */
  public checkUserLogin(url?: string): boolean {
    if (this.checkPrivilege(url)) {
      const userObject = this.utilService.getAuthUser();
      const userToken = this.utilService.getAuthToken();
      const userRoles = this.utilService.getAuthRoles() || [];
      // console.log('DATA ', userObject, userToken, Object.keys(userObject).length < 3, !userToken);
      // console.log('Check guard ', (userObject === undefined || userObject === '' || userObject === null || !!userToken || Object.keys(userObject).length < 3));
      if (userObject === undefined || userObject === '' || userObject === null || !userToken || Object.keys(userObject).length < 3) {
        this.cache.deleteSession(ENV.TOKEN);
        return false;
      } else {
        // console.log('Check 2 ',  (userRoles.length < 1 || Object.keys(userObject).indexOf('email') < 0 ), userRoles.length < 1, Object.keys(userObject).indexOf('email') < 0 );
        if (userRoles.length < 1 || Object.keys(userObject).indexOf('email') < 0 ) {
          this.cache.deleteSession(ENV.TOKEN);
          this.cache.deleteStorage(ENV.TOKEN);
          return false;
        }
        return true;
      }
    } else {
      return false;
    }
  }


  /**
   *
   * @returns {boolean}
   */
  public logOut() {
    this.cache.clearSession();
    this.cache.clearStorage();
    return true;
  }
  public logUserOut() {
    this.userService.logOut().subscribe(() => {
      this.logOut();
    });
    this.logOut();
    this.router.navigate(['/']);
  }
}
