import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-attachment-files',
  templateUrl: './attachment-files.component.html',
  styleUrls: ['./attachment-files.component.css']
})
export class AttachmentFilesComponent implements OnInit {
  @Output() updateParentComponent: EventEmitter<any> = new EventEmitter();
  attachmentfiles : any = [];
  constructor() { }

  ngOnInit() {
  }
  public triggerClick(id: string) {
    $(`#${id}`).trigger('click');
  }

  handleAttachmentChange(e) { 
    for (var i = 0; i < e.target.files.length; i++) { 
      this.attachmentfiles.push(e.target.files[i]);
    } 
    this.updateParentComponent.emit(this.attachmentfiles)
  }
  
  removeFile(index) {
    this.attachmentfiles.splice(index, 1);
    this.updateParentComponent.emit(this.attachmentfiles)
  }
}
